@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #1279c9;
  --content-family: "Gilroy";
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #fb1417;
  --success-color: #28a745;
}

html {}

body,
html {
  font-family: var(--content-family);
  letter-spacing: 0.03em;
  padding-right: 0px !important;
}

.modal-open {
  overflow-y: auto;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

h1 {
  font-weight: 800;
  font-size: 55px;
  margin-bottom: 20px;
}

h2 {
  color: #000;
  font-size: 60px;
  font-weight: 800;
}

h2 span {
  color: #ff3d3d;
  display: inline;
  text-transform: uppercase;
}

h3 {}

h4 {
  font-size: 24px;
  font-weight: 600;
}

h6 {
  font-size: 17px;
  font-weight: 600;
}

label {
  font-size: 12px;
  color: #474747;
  font-weight: 500;
}

a:hover {
  text-decoration: none;
}

button:focus,
a:focus,
a:hover {
  text-decoration: none;
  outline: none;
}

:focus {
  outline: none;
}

:hover {
  transition: 0.25s;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

button {
  cursor: pointer;
}

p {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--content-family);
  padding: 0;
  letter-spacing: 0.03em;
}

h5 {
  font-size: 18px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #c7c7c7;
}

::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
  opacity: 0.5;
  transition: all 0.5s ease;
}

::selection {
  background-color: #000000;
  color: #fff;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:focus {
  background-color: #fff;
  border-color: #ababab;

  box-shadow: 0 0 0 0rem rgb(0 123 255 / 0%);
}

.container {
  max-width: 1480px;
}

.container-fluid {
  max-width: 1920px;
}

.box_register .mid_box {
  width: 100%;
}

.register .container {
  max-width: 1700px;
}

.logo-login {
  width: 270px;
}

a.google img.img-fluid {
  margin-right: 40px;
}

p.w-70 {
  width: 85%;
}

/*Register page style start*/

.checkbox_custom input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox_custom label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.checkbox_custom label a {
  color: var(--primary-color);
}

/* 
.checkbox_custom label:before {
    content: '';
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    margin-top: -7px;
}

.checkbox_custom input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    width: 5px;
    height: 11px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */

.checkbox_custom {
  display: flex;
  align-items: center;
}

.checkbox_custom label {
  margin-left: 12px;
}

.checkbox_custom input {
  display: block;
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.test1_001 .form-control:checked {
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'%3E%3C/path%3E%3C/svg%3E");
  background-position: center center;
  transition: unset;
}

.checkbox_custom input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'%3E%3C/path%3E%3C/svg%3E");
  background-position: center center;
  transition: unset;
}

.checkbox_custom input:checked {
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'%3E%3C/path%3E%3C/svg%3E");
  background-position: center center;
  transition: unset;
}

.register {
  padding: 45px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.box_register .mb-60 {
  margin-bottom: 50px;
}

.box_register {
  text-align: center;
  padding: 5px 40px;
  border-radius: 20px;
  height: auto;
  min-height: 593px;
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  border: 1px solid #ffffff6e;
  background: radial-gradient(100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  border: 2px solid #617382;
  /* border-image-source: linear-gradient(169.15deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 96.79%); */
  overflow: hidden;
}

.box_register h4 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 24px;
}

.box_register p {
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  margin-bottom: 10px;
}

.form-1 {
  border: 1px solid #ababab;
  border-radius: 10px;
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  padding: 0 25px 0 43px;
  background-repeat: no-repeat !important;
  background-position: 11px 19px !important;
}

.date_box.card-menu.form-1 .gj-datepicker.gj-datepicker-md.gj-unselectable input {
  line-height: 52px;
  padding: 0;
  font-size: 12px;
}

.loader_custom span {
  margin: auto;
  display: block;
}

.submit-btn {
  height: 50px;
  line-height: 50px;
  background: #fb1417;
  border: 0px;
  color: #fff;
  font-family: var(--content-family);
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 1;
  transition: 0.7s ease;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 50px;
  padding: 0px;
}

.submit-btn:hover {
  background: #000;
  transition: 0.7s ease;
}

.checkbox_custom {
  margin-bottom: 70px;
  position: relative;
}

.left_register {
  /* height: 90%; */
  padding-top: 0;
  padding-bottom: 240px;
}

.left_register h3 {
  font-weight: 700;
  color: #fff;
  font-size: 25px;
  margin-top: 30px;
}

.left_register p {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.box_register h4 span {
  font-size: 15px;
  font-weight: 600;
  display: block;
  color: #888888;
  margin-top: 11px;
}

.term {
  position: absolute;
  right: 0;
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 500;
}

.or {
  text-align: center;
  font-size: 16px;
  color: #505050;
  position: relative;
  margin-top: 30px;
  margin-bottom: 50px;
  font-weight: 600;
}

.or:before {
  content: "";
  width: 45%;
  height: 1px;
  background: #505050;
  position: absolute;
  left: 0;
  top: 50%;
}

.or:after {
  content: "";
  width: 45%;
  height: 1px;
  background: #505050;
  position: absolute;
  right: 0;
  top: 50%;
}

.social_login a {
  display: block;
  background: #efefef;
  margin-bottom: 20px;
  border-radius: 10px;
  /* text-align: center; */
  height: 50px;
  line-height: 50px;
  color: #717171;
  font-size: 16px;
  padding: 0 118px;
  transition: 0.7s ease;
  font-weight: 500;
}

.social_login a:hover {
  background: var(--primary-color);
  color: #fff;
  transition: 0.7s ease;
}

.toggle-password {
  position: absolute;
  top: 34%;
  right: 12px;
  color: #c4c4c4;
}

.or.sign:before,
.or.sign:after {
  width: 32%;
}

.icon_form {
  position: absolute;
  left: 14px;
  top: 50%;
  width: 16px;
  transform: translateY(-50%);
}

.mid_box .form-group {
  position: relative;
  margin-bottom: 6px;
}

.image_reg {
  background: url("../components/images/register.png");
}

.logo_signup {
  text-align: center;
  border-bottom: 1px solid #8a8e91;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.logo_signup svg {
  width: 160px;
}

.box_register label {
  display: block;
  text-align: left;
}

.email_img {
  /*background: #fff url(../components/images/email.png) !important;*/
  background-repeat: no-repeat !important;
  background-position: 12px 13px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.email_ic {
  position: relative;
}

.email_ic:after {
  content: "";
  background: #ffffff url(../components/images/email.png) !important;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 15px;
  background-position: left top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.password_ic:after {
  content: "";
  background: #ffffff url(../components/images/lock.png) !important;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 15px;
  background-position: left top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.name_ic:after {
  content: "";
  background: #ffffff url(../components/images/user.png) !important;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 15px;
  background-position: left top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.phone_ic:after {
  content: "";
  background: #ffffff url(../components/images/phone.png) !important;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 15px;
  background-position: left top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.form-group.checkbox_custom {
  margin-bottom: 20px;
  margin-top: 12px;
}

.box_register p.already {
  margin-top: 13px;
  margin-bottom: 0 !important;
  font-weight: 600;
}

.already a {
  color: #fff;
}

.error {
  color: #ffbfbf;
  font-size: 8px;
  margin-top: 2px;
}

.form_signup {
  padding: 0 30px;
}

.image_sign {
  background: url("../components/images/login.png");
}

section.register.image_sign .box_register {
  background: radial-gradient(100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  border: 2px solid #79797d;
}

.sign {
  margin: 35px 0;
}

.forgot {
  margin: 20px 0 40px 0;
}

.forgot a {
  margin-top: 13px;
  font-weight: 500;
  font-size: 14px;
}

header.header {
  background: #010101;
  padding: 18px 0;
}

.dropdown_user a:after {
  display: none;
}

.dropdown_user .dropdown-menu.menu_top1 a {
  color: #000;
  padding: 5px 10px;
}

.dropdown_user .dropdown-menu.menu_top1 .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff3d3d;
}

.main_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown_user:before {
  display: none;
}

.img_user {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
}

.img_user img.img-fluid {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown_user a#dropdownMenuButton {
  padding-bottom: 0;
  display: block;
  display: flex;
  align-items: center;
}

.dropdown_user span {
  margin-right: 10px;
}

ul.menu_top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
}

ul.menu_top li {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

ul.menu_top li a {
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  transition: 0.7s ease;
  font-weight: 500;
  padding-bottom: 10px;
  position: relative;
}

ul.menu_top li a:after {
  content: "";
  background: #fb1417;
  height: 1.94px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.7s ease;
  margin: 0 auto;
  text-align: center;
  width: 0px;
}

ul.menu_top li a:hover:after {
  width: 100%;
  transition: 0.7s ease;
}

ul.menu_top li a.btn-1 {
  background: #c00000;
  height: 40px;
  line-height: 40px;
  font-weight: 600 !important;
  padding: 0 30px !important;
  display: block;
  border-radius: 70px;
}

a.btn-1:after {
  display: none;
}

ul.menu_top.right_menu li:last-child:before {
  content: "";
  width: 1px;
  height: 80%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 10%;
}

ul.menu_top.right_menu li {
  position: relative;
}

ul.menu_top li a.btn-1:hover {
  background: #fff;
  color: #c00000;
}

/*Header  style end*/
/*Home  style start*/
.box_banner {
  background: #100f0fc2;
  border-radius: 15px;
  padding: 30px;
}

.box_banner h2 {
  color: #fff;
}

.box_banner h2 span {
  color: #ff3d3d;
  display: block;
}

.home_001 {
  height: 90vh;
  background: url(../components/images/banner-home.png);
  background-position: center right;
  background-size: 100%;
  background-repeat: no-repeat;
}

.home_002 {
  height: 800px;
  background: url(../components/images/banner2.png);
  background-position: center right;
  background-size: 100%;
  background-repeat: no-repeat;
}

.home_003 {
  padding: 120px 0;
}

.home_003 h2 {
  margin-bottom: 40px;
}

.theme_red_btn1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  padding: 4px 30px;
  border-radius: 70px;
  color: #fff !important;
  border: 2px solid #fb1417;
  text-transform: uppercase;
  background: #fb1417;
  display: inline-block;
  margin-bottom: 10px;
}

.start-btn {
  height: 55px;
  padding: 0 60px;
}

.timer_box .timer-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timer_box .timer-btn svg {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
}

.timer_box .timer-btn small {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.theme_btn1 {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #000000;
  /* color: #fb1417 !important; */
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #000000;
  margin-bottom: 10px;
}

theme_btn_black:active {
  background: #000000;
  border: 2px solid #000000;
}

.theme_btn1:hover {
  background: #000000;
  border: 2px solid #000000;
}

.theme_btn1:focus {
  background: #000000;
  border: 2px solid #000000;
}

.home_004 {
  background: #3d3a38;
  padding: 80px 100px 50px 100px;
  font-family: "Gilroy";
}

.home_004 h2 {
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #ffffff;
}

.home_004 h5 {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.home_004 h6 {
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 0;
}

.home_004 .Profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home_004 .img-cover {
  min-width: 222px;
  max-width: 222px;
  max-height: 300px;
  height: 300px;
  overflow: hidden;
  /*margin: auto;*/
}

.home_004 .img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home_004 p {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.03em;
  color: #000000;
}

.home_004 a {
  text-decoration: none;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  background: #ffffff;
  padding: 10px 10px 10px 20px;
}

.slide-content p {
  margin: 0;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.profile-info p {
  line-height: normal;
}

.home_004 .swiper-slide {
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding-top: 57px;
  padding-bottom: 50px;
}

.home_004 .swiper {
  padding-bottom: 65px;
}

.home_004 .text-center {
  margin-bottom: 47px;
}

.swiper-pagination-bullet-active {
  background-color: #fb1417 !important;
}

.home_005 {
  height: 600px;
  position: relative;
}

.show_mob {
  display: none;
}

.home_005:after {
  content: "";
  height: 100%;
  width: 50%;
  background: url(../components/images/img1.png);
  position: absolute;
  right: 0;
  /* top: 0; */
  bottom: 0;
  z-index: 9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home_005 h2 {
  position: relative;
  z-index: 99;
}

.home_005 h2 {
  padding-left: 40px;
}

.home_006:before {
  content: "";
  height: 100%;
  width: 50%;
  background: url(../components/images/img3.png);
  position: absolute;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home_006 h2 {
  position: relative;
  z-index: 99;
}

.home_006 {
  position: relative;
  height: 600px;
}

.home_007:before {
  content: "";
  height: 73%;
  width: 50%;
  background: url(../components/images/img5.png);
  position: absolute;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
  top: 9%;
}

.home_007 h2 {
  position: relative;
  z-index: 99;
}

.home_007 {
  position: relative;
  height: 700px;
}

.home_007 h2 span {
  display: inline-block;
}

.home_007 h2 {
  padding-bottom: 60px;
}

.home_008:after {
  content: "";
  height: 100%;
  width: 50%;
  background: url(../components/images/img6.png);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home_008 h2 {
  position: relative;
  z-index: 99;
}

.home_008 {
  position: relative;
  height: 600px;
  margin-top: -60px;
}

.home_009 {
  padding: 60px 0;
  position: relative;
}

.home_009:before {
  content: "";
  height: 100%;
  width: 100%;
  background: #f9f9f9;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.home_009 p {
  font-size: 26px;
  color: #000;
  font-weight: 600;
  line-height: 40px;
  margin-top: 20px;
}

.pb-50 {
  padding-bottom: 80px;
}

.home_0012 {
  padding: 100px 0;
}

.home_0012 p {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 17px;
}

.home_0012 p.each {
  color: #fb1417;
}

ul.list_pain {
  margin-top: 20px;
}

ul.list_pain li {
  position: relative;
  padding-left: 27px;
  padding-bottom: 14px;
}

ul.list_pain li svg {
  position: absolute;
  width: 17px;
  left: 0;
  top: 2px;
}

ul.list_pain li p {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

.home_0013 {
  height: 600px;
  position: relative;
}

.home_0013:before {
  content: "";
  height: 78%;
  width: 50%;
  background: url(../components/images/img8.png);
  position: absolute;
  left: 0;
  top: 8%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 99;
}

.home_0013:after {
  content: "";
  height: 100%;
  width: 90%;
  background: #f9f9f9;
  position: absolute;
  bottom: 0;
  right: 0;
}

.z-in {
  z-index: 99999;
  position: relative;
}

.home_0013 h5 {
  font-size: 22px;
  font-weight: 600;
}

.footer {
  background: #000000;
  padding-top: 100px;
}

.bx_footer {
  padding-right: 100px;
}

.bx_footer p {
  color: #fff;
  font-size: 15px;
}

.footer_logo {
  margin-bottom: 26px;
}

ul.cards {
  margin-top: 50px;
  display: flex;
}

ul.cards li {
  padding: 0 20px;
  position: relative;
}

ul.cards li div a {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

ul.cards li:first-child {
  padding-left: 0px;
}

ul.cards li:after {
  content: "";
  width: 3px;
  height: 3px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 9px;
  border-radius: 15px;
}

ul.cards li:last-child:after {
  display: none;
}

.footer h6 {
  letter-spacing: 0.03em;
  color: #fb1417;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

ul.menu_footer li {
  margin-bottom: 10px;
}

ul.menu_footer li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.help p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.help p a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

ul.menu_footer.two {
  display: flex;
  flex-wrap: wrap;
}

.menu_footer.two li {
  width: 50%;
}

.bottom_footer {
  background: #000;
  padding-bottom: 3px;
  padding-top: 30px;
}

section.bottom_footer p,
section.bottom_footer p a {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.right {
  text-align: right;
}

.bt01 {
  border-top: 1px solid #ffffff85;
  padding: 20px 0;
}

section.bottom_footer p a:hover,
ul.cards li div a:hover,
ul.menu_footer li a:hover {
  color: #fb1417;
}

/*Home  style end*/
/*welcome  style start*/
.welcome_001 {
  background: url(../components/images/inner_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  background-size: 100%;
}

#welcome .ssa:before {
  display: none;
}

.box_banner_inner {
  background: #100f0fc2;
  border-radius: 15px;
  padding: 30px;
  color: #fff;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_banner_inner h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.box_banner_inner h5 {
  font-size: 26px;
  font-weight: 600;
}

.box_banner_inner p {
  font-size: 20px;
  font-weight: 600;
}

ul.breadcrumb.main {
  background: #fff;
  margin: 0;
  padding: 20px 0;

}

.welcome_002 .breadcrumb-block {
  max-width: 710px;
  width: 100%;
  margin: 0 auto !important;
}

ul.breadcrumb.main li {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

ul.breadcrumb.main li.active a {
  color: #000;
}

ul.breadcrumb.main li a {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 500;
}

ul.breadcrumb.main li:after {
  content: "/";
  color: #c4c4c4;
  position: absolute;
  right: -5px;
  top: 0;
}

ul.breadcrumb.main li:first-child {
  padding-left: 0;
}

ul.breadcrumb.main li:last-child:after {
  display: none;
}

.img_video {
  height: 400px;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 40px;
  position: relative;
}

.img_video img.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon_vid {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.icon_vid svg {
  width: 60px;
  height: 60px;
}

.img_video:before {
  content: "";
  background: #0000008c;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.welcome_003 {
  padding-bottom: 70px;
  padding-top: 40px;
}

/*welcome  style end*/
/*assestment day1 style start*/

.assestmentd1_001 {
  background: #f9f9f9;
  padding: 32px 0;
}

.assestmentd1_001 h3 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
}

.button-switch {
  font-size: 1.5em;
  height: 23px;
  position: relative;
  width: 50px;
}

.button-switch .lbl-off,
.button-switch .lbl-on {
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 0.5em;
  transition: opacity 0.25s ease-out 0.1s;
  text-transform: uppercase;
}

.button-switch .lbl-off {
  right: 0.4375em;
}

.button-switch .lbl-on {
  color: #fefefe;
  opacity: 0;
  left: 0.4375em;
}

.button-switch .switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 0;
  font-size: 1em;
  left: 0;
  line-height: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 0;
}

.button-switch .switch:before,
.button-switch .switch:after {
  content: "";
  font-size: 1em;
  position: absolute;
}

.button-switch .switch:before {
  border-radius: 1.25em;
  background: #c7c7c7;
  height: 18px;
  left: 0;
  top: 2px;
  transition: background-color 0.25s ease-out 0.1s;
  width: 41px;
}

.button-switch .switch:after {
  border-radius: 50%;
  background: #fb1417;
  height: 22px;
  transform: translate(0px, 0px);
  transition: transform 0.25s ease-out 0.1s;
  width: 22px;
}

.button-switch .switch:checked:after {
  transform: translate(20px, 0px);
}

.button-switch .switch:checked~.lbl-off {
  opacity: 0;
}

.button-switch .switch:checked~.lbl-on {
  opacity: 1;
}

.button-switch .switch:checked:before {
  background: #c7c7c7;
}

.button-switch .switch#switch-blue:checked:before {
  background: #3498db;
}

.nav_week {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.daily {
  padding-right: 10px;
}

.nav_week a {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000;
}

.weekly {
  padding-left: 10px;
}

.suggested {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.suggested li {
  position: relative;
  padding-left: 18px;
  font-size: 14px;
  font-weight: 500;
}

.suggested li:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 0;
  background: #000;
  top: 5px;
}

.suggested li.active:before {
  background: #fb1417;
}

.suggested li:last-child {
  margin-left: 40px;
}

@keyframes progress {
  0% {
    --percentage: 0;
  }

  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #000;
  --secondary: #ff0000;
  --size: 230px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
  border: 14px solid #f9f9f9;
  margin: 0 auto;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(var(--primary) calc(var(--percentage) * 1%),
      var(--secondary) 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 59%, #000 0);
  -webkit-mask-mode: alpha;
  transform: rotate(-90deg);
}

[role="progressbar"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  font-size: 30px;
  font-weight: 600;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #fff; */
  width: 100%;
  height: 100%;
  border: 45px solid #f9f9f9;
  border-radius: 50%;
}

.shadow_box {
  background: #ffffff;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 40px;
  font-family: "Poppins", serif !important;
}

.assestmentd1_003 h4 {
  font-weight: 700;
}

.assestmentd1_002 {
  padding-bottom: 50px;
}

ul.time_list {
  margin-top: 14px;
}

ul.time_list li {
  display: flex;
}

ul.time_list li span.red {
  color: #fb1417;
}

ul.time_list li span {
  font-weight: 500;
  width: 44%;
  color: #000;
  font-size: 14px;
  margin-bottom: 14px;
}

.img_box {
  text-align: center;
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  height: 250px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.img_box img.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.assestmentd1_003 {
  padding-bottom: 80px;
}

/*assestment day1 style end*/
/*assestment test1 style start*/
.test_one .checkbox_custom label {
  color: #000000;
}

.test_one .checkbox_custom label:before {
  border: 1px solid #737373;
  background-color: #f9f9f9;
}

.theme_btn1.black {
  background: #000;
  border-color: #000;
}

.border-t {
  padding-top: 20px;
  position: absolute;
  bottom: 25px;
  width: 93%;
  left: 20px;
}

.border-b {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.education a {
  border: 1px solid #737373;
  display: block;
  text-align: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.education a svg {
  display: block;
  text-align: center;
  margin: 0 auto 3px;
}

.test1_001 {
  padding-bottom: 150px;
  padding-top: 20px;
}

.box_test {
  background: #ffffff;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  padding: 20px;
  height: 100%;
  width: 100%;
  position: relative;
}

.theme_btn1.black:hover {
  background: #fb1417;
}

div#video_controls_bar {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  cursor: pointer;
  z-index: 99;
}

div#video_player_box {
  position: relative;
}

.box_video {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 6px 39px rgb(196 203 214 / 20%);
}

.box_video .timer {
  position: absolute;
  bottom: 50%;
  left: 39%;
  font-size: 200px;
  background-color: transparent;
  font-weight: 800;
  color: #fb1417 !important;
}

.custom-height {
  height: 85%;
}

.box_video .feel-it-here {
  position: absolute;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  /*border: 1px solid rgba(2, 2, 2, 0.2);*/
  border-radius: 5px;
  overflow: hidden;
  transition: all 1s ease;
}

.box_video .feel-it-here.top-left {
  top: 5px;
  left: 5px;
}

.box_video .feel-it-here.top-right {
  top: 5px;
  right: 5px;
}

.box_video .feel-it-here.bottom-left {
  bottom: 5px;
  left: 5px;
}

.box_video .feel-it-here.bottom-right {
  bottom: 5px;
  right: 5px;
}

.box_video .text-placement {
  position: absolute;
}

.box_video .text-placement.top-left {
  top: 5px;
  left: 5px;
}

.box_video .text-placement.top-right {
  top: 5px;
  right: 5px;
}

.box_video .text-placement.bottom-left {
  bottom: 5px;
  left: 5px;
}

.box_video .text-placement.bottom-right {
  bottom: 5px;
  right: 5px;
}

.box_video .text-placement.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background: rgba(255, 255, 255, 0.8);
}

.box_video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.box_video:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay_vid {
  /*background: #0000006e;*/
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.box_test h4 {
  margin-bottom: 50px;
  font-weight: 700;
}

.modal_test .modal-content {}

.modal_test .modal-content .modal-body {
  padding: 40px;
  text-align: center;
  font-size: 22px;
}

.modal_test .modal-content .modal-body h4 {
  margin-bottom: 40px;
  line-height: 36px;
  font-size: 22px;
  font-weight: 600;
}

.theme_btn1.small_btn {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 30px;
}

.theme_btn1.small_btn:focus {
  background: #fb1417;
  border: 2px solid #fb1417;
}

.timer_step {
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
}

.timer_step:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #f1f2f6;
  bottom: 29px;
}

.timer_step li.active {}

.timer_step li {
  text-align: center;
  z-index: 97;
  width: 100%;
}

.timer_step li p {
  margin: auto;
  color: 737373;
  font-weight: 500;
  font-size: 12px;
  width: 90%;
}

.assestmenttimwe_001 {
  background: #f9f9f9;
  padding: 35px 0 35px 0;
}

.assestmenttimwe_001 p {
  width: 120px;
  position: relative;
  top: 30px;
  left: 7px;
}

.timer_step li:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #f1f2f6;
  position: absolute;
  border-radius: 50%;
  bottom: 22px;
}

.timer_step li.active:before {
  border: 2px solid #fb1417;
  background: #f9f9f9;
}

.timer_step li.active p {
  color: #fb1417;
}

/*assestment test1 style end*/
/*assestment timer1 style start*/
.box_test.ex_box {
  height: initial !important;
}

.border-t.position-relative {
  /* border: 0; */
  bottom: 0;
  position: initial !important;
  width: 100%;
  padding-top: 40px;
}

.box_test h4.mb-20 {
  margin-bottom: 20px;
}

.exc_p {
  padding-top: 14px;
  border-top: 1px solid #0909095e;
}

.exc_p p {
  font-size: 14px;
  font-weight: 500;
}

.exc_p p span {
  color: #fb1417;
}

.list_exs {
  margin-bottom: 25px;
}

.list_exs li {
  position: relative;
  padding-left: 14px;
  margin-bottom: 10px;
}

.list_exs li svg {
  position: absolute;
  left: 0;
  top: 8px;
}

.list_exs li p {
  margin: 0;
  font-size: 14px;
}

.box_test.ex_box h6 {
  font-weight: 700;
  margin-bottom: 13px;
}

.puse {
  background: #fe7e00;
  border: 2px solid #fe7e00;
  margin-right: 20px;
}

.next {
  background: #ffffff;
  border: 2px solid #e5e5e5;
  color: #e5e5e5 !important;
}

.puse:hover {
  border: 2px solid #000000;
}

.next:hover {
  border: 2px solid #000000 !important;
}

.timer strong {
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
  display: block;
}

#chart {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
}

#data {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

#data ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#data li {
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.bar {
  fill: #4f009e;
}

/* Table */
#data-table {
  border: none;
  /* Turn off all borders */
  border-top: 1px solid #ccc;
  width: 60%;
}

#data-table th,
#data-table td {
  border: none;
  /* Turn off all borders */
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding: 10px;
  text-align: left;
}

/* Toggle */
.toggles {
  background: #ebebeb;
  color: #545454;
  height: 20px;
  padding: 15px;
}

.toggles p {
  margin: 0;
}

.toggles a {
  background: #222;
  border-radius: 3px;
  color: #fff;
  display: block;
  float: left;
  margin: 0 10px 0 0;
  padding: 0 6px;
  text-decoration: none;
}

.toggles a:hover {
  background: #666;
}

#reset-graph-button {
  float: right;
}

/* Graph */
/* Containers */
#wrapper {
  margin: 10px auto;
  width: 100%;
  border-top: 1px solid #09090926;
}

#figure {
  height: auto;
  position: relative;
  margin-bottom: 20px;
}

#figure ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.graph {
  height: 283px;
  position: relative;
}

/* Legend */
.legend {
  background: #f0f0f0;
  border-radius: 4px;
  bottom: 0;
  position: absolute;
  text-align: left;
  width: 540px;
}

.legend li {
  display: block;
  float: left;
  height: 20px;
  margin: 0;
  padding: 10px 30px;
  width: 120px;
}

.legend span.icon {
  background-position: 50% 0;
  border-radius: 2px;
  display: block;
  float: left;
  height: 16px;
  margin: 2px 10px 0 0;
  width: 16px;
}

/* X-Axis */
.x-axis {
  bottom: -10px;
  color: #555;
  position: absolute;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.x-axis li {
  float: left;
  margin: 0 15px;
  padding: 5px 0;
  width: 21%;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
}

.x-axis li span {
  /* float: left; */
}

/* Y-Axis */
.y-axis {
  color: #555;
  position: absolute;
  text-align: right;
  width: 100%;
  opacity: 0;
}

.y-axis li {
  border-top: 1px solid #ccc;
  display: block;
  height: 62px;
  width: 100%;
}

.y-axis li span {
  display: block;
  margin: -10px 0 0 -60px;
  padding: 0 10px;
  width: 40px;
}

/* Graph Bars */
.bars {
  height: 253px;
  position: absolute;
  width: 100%;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
}

.bar-group {
  float: left;
  height: 100%;
  margin: 0 15px;
  position: relative;
  width: 21%;
}

.bar {
  border-radius: 0px 0px 0 0;
  bottom: 0;
  cursor: pointer;
  height: 0;
  position: absolute;
  text-align: center;
  width: 50px;
}

.bar span {
  background: #fefefe;
  border-radius: 3px;
  left: -8px;
  display: none;
  margin: 0;
  position: relative;
  text-shadow: rgba(255, 255, 255, 0.8) 0 1px 0;
  width: 40px;
  z-index: 20;
  display: none !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.6) 0 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.6) 0 1px 4px;
}

.bar:hover span {
  display: block;
  margin-top: -25px;
}

#data-table.js {
  display: none;
}

.bar span {
  background: #fefefe;
}

.fig0 {
  background: #a22;
}

.bars .bar-group:nth-child(1) {}

.bars .bar-group:nth-child(2) {}

.bars .bar-group:nth-child(3) {}

.bars .bar-group:nth-child(1) .fig0 {
  background: #00b050;
}

.bars .bar-group:nth-child(2) .fig0 {
  background: #0070c0;
  opacity: 0.15;
}

.bars .bar-group:nth-child(3) .fig0 {
  background: #c00000;
  opacity: 0.15;
}

.bar_dropdown {
  width: 280px;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
}

.x-axis li:nth-child(1):before {
  content: "Level 1 ";
  z-index: 99;
  position: relative;
  font-size: 10px;
  opacity: 0.5;
  color: #000;
  font-weight: 500;
}

.x-axis li:nth-child(2):before {
  content: "Level 2";
  opacity: 0.5;
  color: #000;
  font-weight: 500;
}

.x-axis li:nth-child(3):before {
  content: "Level 3";
  opacity: 0.5;
  color: #000;
  font-weight: 500;
}

.x-axis li:nth-child(1) span {
  color: #00b050;
}

.x-axis li:nth-child(2) span {
  color: #0070c0;
}

.x-axis li:nth-child(3) span {
  color: #c00000;
}

.bar_dropdown h4 {
  margin-bottom: 20px;
  /* border-bottom: 1px solid #09090926; */
}

.icon_bar {
  cursor: pointer;
  z-index: 999;
}

.overlay1 {
  /*overflow: hidden;*/
  /* background: #00000073; */
  /*position: relative;*/
}

.overlay1:after {
  /*content: '';*/
  width: 100%;
  height: 100%;
  background: #00000069;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.overlay.active {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 999;
  background: #0000004f;
}

.btn_chart {
  padding-top: 18px;
  border-top: 1px solid #dadada;
}


.timer_box ul {
  display: flex;
  /* background: #f9f9f9; */
  border-radius: 10px;
  padding: 20px 0;
  align-items: center;
  min-height: 115px;
}

.timer_box ul li {
  text-align: center;
  width: 25%;
  height: 100%;
  /* padding: 0 12px; */
  /* border-left: 1px solid #00000030; */
}

.timer_box ul li svg {
  width: 25px;
  height: 25px;
  margin-bottom: 4px;
}

.timer_box ul li .info-icon svg path {
  fill: #000;
}

.timer_box ul li p {
  margin: 0;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px;
  color: #000;
}

.timer_box ul li h6 {
  font-size: 13px;
  margin: 0 0 4px 0;
  font-weight: 600;
  color: #000000;
}

.timer_box ul li .black-line {
  border-top: 2px solid #000;
}

.timer_box ul li:nth-child(1) {
  border-left: 0px solid #00000030 !important;
}

.level {
  color: #00b050 !important;
}

.level2 {
  color: #0070c0 !important;
}

.level3 {
  color: #c00000 !important;
}

.form-2 {
  height: 20px;
  line-height: 20px;
  border: 1px solid #f9f9f9;
  /* background: #f9f9f9; */
  text-align: center;
  border-bottom: 1px solid #090909;
  padding: 0;
  font-size: 14px;
  border-radius: 0;
}

.form-2:focus {
  border: 1px solid #f9f9f9;
  background: #f9f9f9;
  border-bottom: 1px solid #090909;
}

.timer_box .border_1 li:nth-child(2) {
  /* border-left: 1px solid #00000030;
  border-right: 1px solid #00000030; */
}

.timer_box .border_1 li:nth-child(3) {
  border: 0px;
}

.theme_btn1.black:hover {
  border: 2px solid #fb1417;
}

.theme_btn1.next_black {
  border: 2px solid #000;
  background: #fff;
  color: #000 !important;
}

.theme_btn1.next_black:hover {
  border: 2px solid #fb1417;
  background: #fff;
  color: #fb1417 !important;
}

.mr-20 {
  margin-right: 20px;
}

.timer_step li.done:before {
  background: #fb1417;
}

/*assestment timer1 style end*/
/*Change level style start*/
.change_level_001 {
  background: #f9f9f9;
  padding: 35px 0;
  font-family: var(--content-family);
  letter-spacing: 0.03em;
}

.change_level_001 h4 {
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.change_level_001 h4 span {
  color: #fb1417;
}

.change_level_002 {
  padding: 50px 0;
  font-family: var(--content-family);
  letter-spacing: 0.03em;
}

.change_level_002 .level01 h4 {
  font-weight: 700;
  margin-bottom: 17px;
}

.change_level_002 .level01 p {
  font-weight: 500;
  margin-bottom: 8px;
}

.level01 {
  margin-bottom: 20px;
}

.checkbox_list {
  border-radius: 15px;
}

.checkbox_list li {
  border-radius: 15px;
}

.checkbox_list li:last-child {
  margin-right: 0px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
  color: #000;
  border: 2px solid #fb1417;
  display: block;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
  display: block;
}

.container-checkbox1 .checkmark1 {
  width: 300px;
  height: 120px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  display: block;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-checkbox1 .checkmark1 svg {
  width: 60px;
  height: 60px;
  fill: #41527d;
}

.container-checkbox1 input:checked~.checkmark1 {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}

.container-checkbox1 .checkmark1 h3 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.container-checkbox1 .checkmark1 p {
  font-size: 15px;
  font-weight: 200;
  color: #fff;
  margin: 0;
}

.checkmark1 span {
  margin-top: 20px;
}

.container-checkbox1:hover input~.checkmark1 {
  background-color: #f3faff;
}

.container-checkbox input,
.container-checkbox1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

span.checkmark.main_check {
  display: block;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  padding: 14px;
  border: 2px solid #ccc0;
  cursor: pointer;
}

.box_levelimg {
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  border-radius: 15px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_levelimg img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
}

.checkmark.main_check {}

.checkmark.main_check h6 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 15px;
}

.checkmark.main_check p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.checkmark.main_check p span {
  color: #fb1417;
}

/*Change level style end*/
/*Grid view style start*/
.mt-30 {
  margin-top: 30px;
}

.box_week button.slick-prev {
  z-index: 9999;
  position: absolute;
  background: url(../components/images/left.png);
  font-size: 0;
  border: 0;
  width: 14px;
  height: 16px;
  padding: 0;
  background-repeat: no-repeat;
  right: 60px;
  background-size: contain;
}

.box_week button.slick-next {
  position: absolute;
  right: 30px;
  background: url(../components/images/right.png);
  font-size: 0;
  border: 0;
  width: 14px;
  height: 16px;
  padding: 0;
  top: 25px;
  background-repeat: no-repeat;
  background-size: contain;
}

.box_week {
  background: #000;
  border-radius: 0px 0px 11px 11px;
  /*padding: 25px;*/
  position: relative;
}

.box_week .slick-slide {
  height: auto !important;
}

.box_week .carousel-item.active {
  display: flex;
}

.box_week p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.box_week h6 {
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  width: 30%;
}

.box_date {
  display: flex;
  height: 100%;
  background: #f9f9f9;
  align-items: center;
  padding: 15px 0px 15px 20px;
  border-radius: 0px 0px 11px 0px;
  flex: 1;
}

.box_date p {
  /*! display: block; */
  margin: 0;
  font-weight: 600;
  color: #000000;
  width: 10%;
  /* text-align: center; */
}

.date_week {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.date_week li {
  width: 30%;
  border-right: 1px solid #00000030;
  display: flex;
  align-items: center;
}

.date_week li:last-child {
  border: 0;
}

.date_week li p {
  text-align: center;
  font-size: 16px;
  width: 52px;
  margin: 0 auto;
  line-height: 19px;
  padding: 14px 0;
  border-radius: 5px;
}

.date_week li p.active {
  background: #fb1417;
  color: #fff;
}

.date_week li p span {
  display: block;
}

.left_tabs li a {
  background: #ffffff;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px !important;
  padding: 14px;
  margin-bottom: 25px;
  border: 2px solid transparent !important;
}

.box_levelimg1 {
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 15px;
}

.box_levelimg1 img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}

.left_tabs .nav-item.show .nav-link,
.left_tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border: 2px solid #fb1417 !important;
  border-radius: 15px !important;
}

.title_box h6 {
  font-weight: 600;
  /* font-size: 14px; */
  font-size: 13px;
  padding-right: 30px;
  color: #010101;
  margin-bottom: unset;
}

.red-title {
  color: #c00000 !important;
}

.icon_level {
  /*position: absolute;*/
  /*top: 0;*/
  /*right: 0;*/
}

.info_icon {
  position: absolute;
  top: 30px;
  right: 33px;
  /* left: -10; */
}

.info_icon_ic {
  width: 25px !important;
  height: 25px !important;
  /* border-radius: 50%; */
  object-fit: cover;
}

.title_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

/*Summary Page */
.summary_box {
  background: #ffffff;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 15px;
  height: auto;
}

.summary_box_levelimg1 {
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  height: 100%;
  max-height: 400px;
  overflow: hidden;
}

.summary_box_levelimg1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.summary_title_box {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*align-items: center;*/
  margin: 10px 0px;
}

.summary_title_box h6 {
  font-weight: 600;
  font-size: 16px;
  padding-right: 30px;
  color: #010101;
  margin-bottom: unset;
}

ul.summary_time_list {
  margin-top: 14px;
  height: 180px;
  overflow-y: auto;
}

ul.summary_time_list li {
  display: flex;
  justify-content: start;
  align-items: center;
}

ul.summary_time_list li span.red {
  color: #fb1417;
  min-width: 50px;
  margin-bottom: auto;
}

ul.summary_time_list li span.time {
  margin-left: 5px;
}

ul.summary_time_list li span {
  font-weight: 500;
  width: auto;
  color: #000;
  font-size: 14px;
  margin-bottom: 14px;
}

.left_tabs .nav-item {
  margin-bottom: 0px;
}

.icon_level svg {
  width: 24px;
  height: 24px;
}

.grid_001 {
  padding-bottom: 100px;
  padding-top: 30px;
}

ul.nav.nav-tabs.row.left_tabs {
  border-bottom: 0px solid #dee2e6 !important;
}

/* On mouse-over, add a grey background color */
.container-checkbox11:hover input~.checkmark {}

/* When the checkbox is checked, add a blue background */
.container-checkbox11 input:checked~.checkmark {
  color: #fff;
  background: #fb1417;
  display: block;
  border-radius: 5px;
}

/* Show the checkmark when checked */
.container-checkbox11 input:checked~.checkmark:after {
  display: block;
}

.container-checkbox11 .checkmark1 {
  width: 300px;
  height: 120px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  display: block;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-checkbox11 .checkmark1 svg {
  width: 60px;
  height: 60px;
  fill: #41527d;
}

.container-checkbox11 input:checked~.checkmark1 {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}

.container-checkbox11 .checkmark1 h3 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.container-checkbox11 .checkmark1 p {
  font-size: 15px;
  font-weight: 200;
  color: #fff;
  margin: 0;
}

/* . .container-checkbox11:hover input~.checkmark1 {} */

.container-checkbox11 input,
.container-checkbox1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

span.checkmark.main_check {
  display: block;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  padding: 14px;
  border: 2px solid #ccc0;
  cursor: pointer;
}

ul.rating {
  display: flex;
  background: #f9f9f9;
  padding: 6px 0;
  border: 1px solid #737373;
  border-radius: 10px;
}

.container-checkbox11 input:checked~.checkmark p {}

ul.rating li {
  width: 10%;
  text-align: center;
  border-right: 1px solid #f1f2f6;
}

ul.rating li label {
  display: block;
  margin: 0 8px;
  align-items: center;
  /* border-right: 1px solid #F1F2F6; */
  border-radius: 5px;
}

label.container-checkbox11 {}

label.container-checkbox11 span.checkmark.main_check1 p {
  margin: 0;
}

.container-checkbox11 .checkmark {
  padding: 6px 0;
  display: block;
}

.box_state {
  padding: 0 54px;
}

.status_checkout .modal-dialog {
  min-width: 600px;
}

.box_state label {
  font-size: 15px;
  margin-bottom: 10px;
  color: #000000;
}

p.status {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
}

.box_state .form-group {
  margin-bottom: 30px;
}

.box_state textarea.form-control.form-1 {
  background: #f9f9f9;
  resize: none;
  line-height: 20px;
  padding: 10px;
  min-height: 110px;
}

.check_box {}

.check_box .checkbox_custom {
  margin-bottom: 0;
  margin-top: 0;
}

.check_box .checkbox_custom label:before {
  border: 1px solid #737373;
  background: #f9f9f9;
  box-shadow: initial;
}

.check_box .checkbox_custom label {
  color: #737373;
}

.status_checkout h4 {
  font-size: 20px;
  border-bottom: 1px solid #73737342;
  padding-bottom: 12px;
  margin-bottom: 35px;
}

.status_checkout .modal-dialog.modal-dialog-centered .modal-content {
  border-radius: 20px;
}

.box_state select.form-control.form-1 {
  background: #f9f9f9;
  font-size: 13px;
  padding: 0 15px;
  color: #737373;
}

.box_state select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.select {
  position: relative;
}

.select svg {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
}

.open-button {
  background: #eee;
  padding: 10px;
  cursor: pointer;
}

#dropdown-list {
  display: none;
}

#dropdown-list li {
  list-style: none;
}

span.icon_mobile {
  display: none;
}

.hide_mobile {
  display: block;
}

.show_mobile {
  display: none;
}

.mobile_setting .dropdown_user {
  display: none;
}

.chosen-container.chosen-container-multi {
  width: 100% !important;
}

.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: auto;
  border: none;
  background-color: #fff;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(1%, #eee),
      color-stop(15%, #fff));
  background-image: linear-gradient(#eee 1%, #fff 15%);
  cursor: text;
  float: right;
}

div.search-field:last-child {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 999999999;
  background: #f9f9f9;
  height: 50px;
  padding: 0 15px 0px !important;
  border: 1px solid #ababab !important;
  border-radius: 10px;
  margin-top: 1px !important;
}

li.search-choice {
  width: 100%;
}

ul.chosen-choices {
  padding-top: 52px !important;
  border-radius: 10px;
  border: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.chosen-container .chosen-drop {
  position: absolute;
  top: 0 !important;
}

.active-result {}

.active-result:before {
  content: "";
  background-color: #f9f9f9;
  border: 1px solid #737373;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 4px;
  margin-top: -3px;
}

.active-result:after {
  content: "";
}

.result-selected {}

.result-selected:before {
  content: "";
  background-color: #f9f9f9;
  border: 1px solid #737373;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 4px;
  margin-top: -3px;
}

.result-selected:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 12px;
  width: 5px;
  height: 11px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

li.result-selected {
  position: relative;
}

ul.chosen-results li {
  width: 45%;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #09090957;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  font-size: 14px;
  color: #9b9b9b !important;
}

ul.chosen-results {
  display: flex;
  flex-wrap: wrap;
  padding-top: 55px !important;
}

.chosen-container .chosen-results li.highlighted {
  background-color: #fff;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(20%, #ffffff),
      color-stop(90%, #ffffff)) !important;
  background-image: linear-gradient(#ffffff 20%, #ffffff 90%) !important;
  color: #9b9b9b !important;
}

.chosen-container-active .chosen-choices {
  border: 0px solid #5897fb00 !important;
  -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 0%) !important;
  box-shadow: 0 0 5px rgb(0 0 0 / 0%) !important;
}

li.search-field input.chosen-search-input.default {
  /* border: 1px solid #ABABAB !important; */
  border-radius: 10px !important;
  width: 100% !important;
  height: 50px !important;
  line-height: 50px !important;
}

.chosen-drop {
  background: #ffffff !important;
  border-radius: 15px;
  border: 1px solid #fff !important;
  padding: 10px;
  box-shadow: 0px 0px 15px rgba(196, 203, 214, 0.4);
  /* margin: 3px; */
}

.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  width: 100% !important;
  height: 50px !important;
  line-height: 50px !important;
}

.select_icon svg {
  z-index: 999999999;
  top: 21px;
  transform: initial;
}

li.search-choice {
  border: 1px solid #ababab !important;
  padding: 10px !important;
  border-radius: 10px !important;
  background: #f9f9f9 !important;
  width: 91%;
}

ul.chosen-choices {}

li.search-choice span {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

a.search-choice-close {
  position: absolute !important;
  right: -44 !important;
  top: 0 !important;
  width: 9% !important;
  height: 100% !important;
  background: #ffe5e5 url(../components/images/delete.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 17px !important;
  border-radius: 10px;
}

.textarea01 textarea.form-control.form-1 {
  border: 0px;
  padding: 10px 0 10px 0px;
  min-height: 60px;

  position: relative;
}

.chosen-container-multi .chosen-choices li.search-choice {
  margin: 5px 5px 5px 0 !important;
}

/*Grid view style end*/
/*listview view style start*/
.tabs_listview .left_list {
  width: 33%;
}

.tabs_listview .right_list {
  padding-left: 40px;
  width: 67%;
}

.tabs_listview .right_list p {
  color: #000000;
  font-weight: 500;
}

.tabs_listview .right_list ul.steps {}

.tabs_listview .right_list ul.steps li {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}

.tabs_listview .right_list ul.steps li p {
  color: #fb1417;
}

.tabs_listview a.nav-link {
  display: flex;
  position: relative;
}

.tabs_listview .left_list .box_levelimg1 {
  height: 200px;
  width: 200px;
}

.tabs_listview a.nav-link .icon_level.text-right {
  bottom: 20px !important;
  top: initial;
  right: 15px;
}

/*listview view style end*/
/*bodytype  style start*/

.bodytype_001 {
  background: #f1f2f6;
  padding-top: 20px;
}

.bodytype_001 p {
  font-size: 22px;
  line-height: 40px;
}

h2.pain {
  margin-top: 40px;
  color: #fb1417;
  margin-bottom: 10px;
}

.bodytype_002 h4 {
  font-size: 30px;
  line-height: 50px;
  margin-bottom: 50px;
}

.bodytype_002 {
  padding: 80px 0;
}

.bg_01 {
  background: #f1f2f6;
}

.tab-content.box_test.ex_box canvas,
#wrapper1 canvas {
  height: 280px !important;
  margin-left: -20px;
}

/* STATUS CHECKOUT */
.text {
  resize: none;
  margin: auto;
  border: 0;
  animation: auto;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.top {
  /* margin: auto; */
  margin-top: 4%;
  width: 50%;
  border: 2px ridge black;
  background-color: #f1f1f1;
}

h3 {
  text-align: center;
}

.new {
  padding: 20px;
}

.new .form-group {
  display: block;
  margin-bottom: 15px;
}

.new .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.new .form-group label {
  position: relative;
  cursor: pointer;
}

.new .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #212121;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -6px;
}

.new .form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 4px;
  height: 10px;
  border: solid #212121;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.box_week.carousel.slide {
  position: relative;
  /*height: 125px;*/
}

.box_week.carousel.slide .carousel-control-prev {
  position: absolute;
  right: 0;
  width: 4%;
  left: 23%;
}

.box_week.carousel.slide .carousel-control-next {
  right: 0px;
  width: 4%;
  left: 25%;
}

ul.chosen-choices.custom_list {}

ul.chosen-choices.custom_list li {
  position: relative;
  margin-bottom: 10px;
}

ul.chosen-choices.custom_list li textarea {}

ul.chosen-choices.custom_list li span {
  display: block;
}

ul.chosen-choices.custom_list li .search-choice-close {
  right: -44px;
}

ul.chosen-choices.custom_list li .search-choice-close {
  cursor: pointer;
  right: -44px;
}

.textarea_01 {
  background: #f9f9f9;
  border: 0px;
  resize: none;
  font-size: 12px;
  color: #737373;
  width: 100%;
}

/********** ALERTS  *********/

/*.alert {*/
/*    padding: 0.8rem;*/
/*    margin:  0;*/
/*    opacity: 0.9;*/
/*    background: black;*/
/*    color: #333;*/
/*}*/

/*.alert-primary {*/
/*    background: var(--primary-color);*/
/*    color: #fff;*/
/*}*/

/*.alert-light {*/
/*    background: var(--light-color);*/
/*    color: #333;*/
/*}*/

/*.alert-dark {*/
/*    background: var(--dark-color);*/
/*    color: #fff;*/
/*}*/

/*.alert-danger {*/
/*    background: var(--danger-color);*/
/*    color: #fff;*/
/*}*/

/*.alert-success {*/
/*    background: var(--success-color);*/
/*    color: #fff;*/
/*}*/

/*.alert-white {*/
/*    background: #fff;*/
/*    color: #333;*/
/*    border: #ccc solid 1px;*/
/*}*/

/*.alert-wrapper {*/
/*    position: fixed;*/
/*    top: 4rem;*/
/*    right: 2rem;*/
/*    display: inline-block;*/
/*}*/
/*bodtype  style end*/

.center-loader span {
  margin: 20px auto;
}

ul {
  padding: 0px;
}

/* li {
  list-style: none;
} */

.test_content h3 {
  text-decoration: underline;
  text-align: start;
}

.test_content p {
  color: #000;
  font-weight: 400;
  margin-top: 20px;
  font-family: "Calibri";
}

.test_videobox {
  text-align: center;
  height: 100%;
  width: 100%;
}

.check_test {
  height: 100%;
  width: 100%;
}

.test_videobox input {
  position: absolute;
  left: -2%;
  width: 25px;
  height: 25px;
  top: 50%;
  transform: translate(50%, -50%);
}

.test_videobox {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.test_videobox input:checked~label {
  border: 2px solid #fb1417;
}

.test_videobox label {
  border: 2px solid #80808054;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  height: 95%;
}

.repeat-circuit-btn {
  font-weight: 700;
  padding: 0 10px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  color: blue !important;
  display: inline-block;
  font-size: 20px;
  text-decoration: underline;
  text-transform: uppercase;
}

.theme-btn-link-black {
  height: 55px;
  line-height: 55px;
  color: #fb1417;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  background: #ffffff;
  /* border: 2px solid #e5e5e5; */
  /* color: #e5e5e5 !important; */
  /*padding: 0 30px;*/
  text-decoration: underline;
}

.theme-btn-link {
  height: 55px;
  line-height: 55px;
  color: #fb1417;
  text-transform: uppercase;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  display: block;
  text-align: right;
  text-decoration: underline;
}

ul.list_pain {
  margin-top: 20px;
}

ul.list_pain li {
  position: relative;
  padding-left: 27px;
  padding-bottom: 14px;
}

ul.list_pain li svg {
  position: absolute;
  width: 17px;
  /* left: 0;
    top: 2px; */
}

ul.list_pain1 li span {
  font-size: 18px;
  margin: 5px;
  color: #c13033;
  font-weight: 700;
}

.list_pain1 i.fa.fa-check {
  color: #c13033;
}

.modal_test .modal-content {}

.modal_test .modal-content .modal-body {
  padding: 40px 0px;
  text-align: center;
  font-size: 22px;
}

.modal_test .modal-content .modal-body h4 {
  margin-bottom: 40px;
  line-height: 36px;
  font-size: 22px;
  font-weight: 600;
}

.theme_btn1.small_btn {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 30px;
}

#skip .checkbox_custom label {
  color: #000000;
}

.test_one .checkbox_custom label:before,
#skip .checkbox_custom label:before {
  border: 1px solid #737373;
  background-color: #f9f9f9;
}

/*
* Recommended products styling - MBK
*/
.banner-recomended {
  background: url(../components/images/banner.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 410px;
}

.muscle-banner-recommended {
  /*background: url(../components/images/MuscleLibraryCover.png);*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}

.bannerImgWrap img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.recomended-products {
  padding: 0;
  position: relative;
}

.recomended-products h2 {
  font-family: var(--content-family);
  font-size: 45px;
}

.recomended-products p {
  font-family: "Poppins", "Gilroy", sans-serif;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
}

.page-title {
  margin-top: 30px;
  margin-bottom: 60px;
}

.page-title-terms h3,
.page-title-terms h2,
.page-title-terms h4 {
  text-align: left;
}

.page-title-terms p {
  text-align: left;
}

.product-box {
  position: relative;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 1px solid #fefefe;
  background: #fff;
  box-shadow: 0 0 5px 5px rgba(2, 2, 2, 0.04);
  max-height: 550px;
}

.product-box img.product-img {
  max-width: 80%;
  /* max-height: 200px; */
  /* object-fit: cover; */
  /* object-position: center; */
  /* width: 250px; */
  height: 250px;
  max-height: 250px;
  object-fit: contain;
  object-position: center;
}

.product-info {
  /* height: 100px; */
}

.product-box h3 {
  font-size: 18px;
  text-align: left;
  /* max-height: 40px; */
}

.product-box h3,
.product-box p {
  display: block;
  width: 100%;
  flex: 1;
  min-width: 0;
  /* Important for long words! */
}

.product-box h3 b {
  text-align: center;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  /* Important for long words! */
}

/* .product-box p span {
    text-align: center;
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-family: sans-serif;
    font-style: italic;
    color: #808080;
    font-size: 13px;
} */

.product-box .product-desc {
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 14px;
  font-weight: 500;
  /* white-space: pre-line; */
}

.product-box .product-comments {
  font-style: italic;
  color: #3b71ca;
  font-family: sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 100;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.comment-not-found {
  font-style: italic;
  color: red;
  font-family: sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 100;
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

/* .product-box .product-comments svg {
  font-size: 19px;
}

.product-box .product-comments span {
    margin-left: 5px;
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-family: sans-serif;
    color: #3B71CA;
    font-size: 13px;
}

.product-box .product-comments {
    font-style: italic;
    color: #3B71CA;
    font-family: sans-serif;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
} */
/* 
.product-box .product-comments span {
  margin-left: 5px;
} */

.product-box .main-view {
  border: 1px solid black;
  border-radius: 0px;
  justify-content: center;
  flex-direction: row;
  /* margin: 5px auto; */
}

.product-box .btn-full {
  /* color: #fb1417; */
  background: #fff;
  display: block;
  width: 33%;
  grid-auto-rows: min-content;
  border: 1px solid lightgray;
  border-radius: 2px;
  margin: 1px auto;
}

.logo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.logo-text {
  display: block;
  width: 28%;
  height: 50px;
  text-align: center;
}

.logo-imgage {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.product-box .btn-full img {
  max-height: 30px;
}

.category-box {
  color: #fff;
  background: #c02f35;
  width: 100%;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 10px;
  font-weight: 900;
  max-height: 100px;
  border: 2px solid #000000;
  border-radius: 2px;
  border-style: solid;
  border-width: 8px 8px 8px 8px;
  /* Adjust the values as needed */
  border-color: rgba(80, 18, 18, 0.5) rgba(80, 18, 18, 0.5) transparent transparent;
  /* margin: 5px auto; */
  cursor: pointer;
  flex: 0 1 49%;
}

.shopping-view {
  /* display: block; */
  width: 100%;
  flex: 1;
  border-width: 1px;
  /* min-width: 0; */
  /* Important for long words! */
}

@media only screen and (max-width: 425px) {
  .category-box {
    cursor: pointer;
    flex: 0 1 100%;
  }
}

@media only screen and (max-width: 1024px) {}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.social-links h3 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 22px;
  letter-spacing: 0.06em;
  color: #000000;
}

.social-profile {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
  /* padding-left: 8%; */
  /* padding-right: 15%; */
  /* border-left: 1px solid #090909; */
  /* width: 30%;
  height: 20%; */
  object-position: center;
  position: relative;
}

/* .social-profile ::after {
  content: "";
  width: 1px;
  height: 155px;
  background-color: #090909;
  position: absolute;
  top: 55px;
  left: -32px;
} */

.social-profile .wrapper {
  /* max-width: 100px; */
  max-height: 100px;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.btn-follow {
  background: #000000;
  border: 1.33627px solid #000000;
  border-radius: 26.7253px;
  width: 65%;
  max-height: 40px;
  height: 100%;
  display: inline-block;
  text-align: center;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px;
  margin-top: 10px;
}

.btn-product-follow {
  background: #000000;
  border: 1.33627px solid #000000;
  border-radius: 26.7253px;
  width: 40%;
  max-height: 40px;
  height: 100%;
  display: inline-block;
  text-align: center;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px;
  margin-top: 10px;
}

.btn-product-follow:hover {
  text-decoration: none;
  color: #000000;
  background-color: #f1f2f6;
  font-weight: 800;
  font-size: 14px;
  border: 1.33627px solid #000000;
  transition: 0.8s;
}

.btn-follow:hover {
  text-decoration: none;
  color: #000000;
  background-color: #f1f2f6;
  font-weight: 800;
  font-size: 14px;
  border: 1.33627px solid #000000;
  transition: 0.8s;
}

.content {
  /* padding: 10px 0px 0px; */
  background: #f1f2f6;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.content figure {
  width: 250px;
  margin-bottom: 0;
}

.content figure img {
  height: unset;
}

.kit {
  background: #f1f2f6;
  padding: 94px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kit ul {
  margin-left: 15px;
}

ul.links-icons {
  list-style-type: none;
  display: flex;
  /* width: 40%; */
  align-items: center;
  /* justify-content: center; */
}

ul.links-icons li {
  background: black;
  display: flex;
  border-radius: 24px;
  padding: 12px 12px;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  margin-left: 2%;
}

ul.links-icons li svg {
  color: white;
  font-size: 22px;
}

.kit h3 {
  margin-bottom: 20px;
  margin-top: 20px;
}

/*
* Testimonials products styling - MBK
*/
.banner-testimonials {
  background: url(../components/images/inner_bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.testimonials {
  padding: 0px;
  font-family: "Gilroy";
}

.testimonials .test-wrap {
  padding: 0px 100px 60px;
}

.test-wrap:first-child {
  border-top: unset;
}

.testimonials h2 {
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #000000;
}

.testimonials h5 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}

.testimonials h6 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
}

.testimonials .Profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.testimonials .img-cover {
  min-width: 220px;
  max-width: 220px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  margin-right: 30px;
}

.testimonials p {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  color: #000000;
}

.testimonials .wrapper {
  border-top: 1px solid #c4c4c4;
  padding-top: 57px;
  padding-bottom: 50px;
}

.testimonials .swiper {
  padding-bottom: 65px;
}

.testimonials .text-center {
  margin-bottom: 47px;
}

.test_videobox-education {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.videos_headings {
  background-color: #c13033;
  text-align: center;
  padding: 10px 0px;
}

.detail_span {
  color: #000000;
  display: block;
  font-weight: 800;
}

/*flip video class*/
.flip-video {
  transform: scale(-1, 1);
}

.videos_headings_black {
  background-color: #000000;
  text-align: center;
}

.video_testbox {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.test_videobox-education .video {
  border: 1px solid #80808054;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  max-height: 180px;
}

.test_videobox-education {
  text-align: center;
}

.video_test img {
  position: absolute;
  filter: invert(1);
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_testcontent h6 {
  font-family: var(--content-family);
  padding: 0;
  letter-spacing: 0.03em;
  font-size: 17px;
  font-weight: 600;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input~.checkmark {}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked~.checkmark {
  color: #000;
  border: 2px solid #fb1417;
  display: block;
}

/* Show the checkmark when checked */
.container-checkbox input:checked~.checkmark:after {
  display: block;
}

/* On mouse-over, add a grey background color */
.container-checkbox2:hover input~.checkmark {}

/* When the checkbox is checked, add a blue background */
.container-checkbox2 input:checked~.checkmark {
  color: #000;
  border: 2px solid #6c757d;
  display: block;
}

/* Show the checkmark when checked */
.container-checkbox2 input:checked~.checkmark:after {
  display: block;
}

.container-checkbox1 .checkmark1 {
  width: 300px;
  height: 120px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  display: block;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container-checkbox1 .checkmark1 svg {
  width: 60px;
  height: 60px;
  fill: #41527d;
}

.container-checkbox1 input:checked~.checkmark1 {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}

.container-checkbox1 .checkmark1 h3 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.container-checkbox1 .checkmark1 p {
  font-size: 15px;
  font-weight: 200;
  color: #fff;
  margin: 0;
}

.checkmark1 span {
  margin-top: 20px;
}

.container-checkbox1:hover input~.checkmark1 {
  background-color: #f3faff;
}

.container-checkbox2 input,
.container-checkbox input,
.container-checkbox1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

span.checkmark.main_check {
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 39px rgba(196, 203, 214, 0.2);
  border-radius: 15px;
  padding: 14px;
  border: 2px solid #ccc0;
  cursor: pointer;
  align-items: center;
  min-height: 216px;
}

.box_levelimg {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /*min-height: 169px;*/
}

.box_levelimg video {
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  border-radius: 15px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 354px;
}

.box_levelimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}

.checkmark.main_check h6 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 15px;
}

.checkmark.main_check p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.checkmark.main_check p span {
  color: #fb1417;
}

.change_level_002 .level01 p {
  font-weight: 500;
  margin-bottom: 8px;
}

.mt-50 {
  margin-top: 5rem;
}

.heading {
  text-transform: uppercase;
  font-size: 2.5rem;
}

.level01 {
  margin-bottom: 20px;
}

/*My Profile Page*/
.profile {
  padding-bottom: 50px;
}

.heading-wrapper {
  margin: 2% auto;
}

.update-profile {
  max-width: 300px;
  width: 100%;
  height: 45px;
  border: 1px solid #1a1d24;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  text-transform: uppercase;
  color: #000000;
  background-color: rgba(255, 255, 255, 0);
}

.edit-profile {
  max-width: 160px;
  width: 100%;
  height: 45px;
  border-radius: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
  background-color: rgba(255, 255, 255, 0);
  margin-left: auto;
}

a:hover {
  text-decoration: none;
  color: unset;
}

.img-cont,
.edit-img-cont {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-cont img,
.edit-img-cont img {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  flex-shrink: 0;
  min-width: 100%;
  height: 100%;
}

.profile-info h4 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000000;
}

ul.profile-info li {
  margin-bottom: 4rem;
}

ul.profile-info {
  padding: 20px 30px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.react-tel-input .country-list {
  width: 236px !important;
}

.input-group .btn {
  z-index: 1 !important;
}

.custom-file-upload {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 9;
}

.custom-file-upload .toggleFileUpload {
  background: transparent;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  padding: 6px 6px;
  color: #000000 !important;
  cursor: pointer;
  text-align: center;
}

.custom-file-upload .toggleFileUpload svg {
  margin-right: 5px;
}

.custom-file-upload .toggleFileUpload svg path {
  fill: #000000;
}

.custom-file-upload .toggleFileUpload:hover,
.custom-file-upload .toggleFileUpload:focus {
  color: #9f9f9f !important;
  transition: all 0.5s ease;
}

.custom-file-upload .toggleFileUpload:hover svg path,
.custom-file-upload .toggleFileUpload:focus svg path {
  fill: #9f9f9f;
  transition: all 0.5s ease;
}

.custom-file-upload .form-control {
  display: none;
}

.validationText {
  color: red;
  font-size: 12px;
}

.validationTextAuth {
  color: #fff;
  font-size: 12px;
}

/*Custom video-player*/
.video-player {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.video-player video {
  width: 100%;
  height: auto;
  max-height: 400px;
  border-radius: 15px;
}

/* .react-thumbnail-generator img {
  width: 170px;
  max-height: 110px;
  border-radius: 0px;
  margin-right: 5px;
} */
/*.video-player .controls {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 44px;*/
/*    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgb(72 71 71) 31%, rgb(255 255 255 / 0%) 100%);*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 0 10px;*/
/*    box-sizing: border-box;*/
/*    border-radius: 0 0px 15px 15px;*/
/*}*/
.video-player .controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgb(72 71 71) 31%,
      rgb(255 255 255 / 0%) 100%);
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 0 0px 15px 15px;
}

.controls-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sound-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
}

button.sound-button svg {
  height: 1.5em !important;
  width: 1.5em !important;
  margin-right: 0px !important;
}

.sound-controls:hover {
  border-radius: 30px;
  background-color: #0000004a;
}

.sound-controls:hover input[type="range"] {
  display: block;
  transition: 0.1s ease-in-out;
}

input[type="range"] {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  position: initial !important;
  transform: unset !important;
  /* creating a custom design */
  width: 100%;
  max-width: 60px;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: visible;
  border-radius: 16px;
  height: 6px;
  background: #ccc;
  transition: 0.8s ease-in-out;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

p.video-duration {
  margin-bottom: 0px;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
}

.related-video .video-duration {
  display: none;
}

.video-player .controls button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
}

.video-player .controls button svg {
  height: 2em;
  width: 2em;
  color: #ffffff;
}

.video-player .controls button:focus {
  outline: none;
}

.video-player .controls .play-pause-button {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-player .controls .play-pause-button i {
  font-size: 24px;
  color: #fff;
}

.video-player .controls .progress-bar {
  flex: 1;
  height: 6px;
  border-radius: 3px;
  background-color: #b0b0b0;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

.video-player .controls .progress {
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-player .controls .time {
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}

.test_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.text-area {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #fb1417;
  border-color: #fb1417;
  box-shadow: unset;
}

.timer {
  border: none !important;
  width: 190px;
  height: auto;
  line-height: 50px;
  font-size: 36px;
  text-align: left;
  margin: 5px;
  font-weight: 700;
}

.loader_custom {
  width: 100%;
}

.theme_btn_black {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #000000;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #000000;
  margin-bottom: 10px;
}

.theme_btn_black:active {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #000000;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #000000;
  margin-bottom: 10px;
}

.theme_btn_black:focus {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #000000;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #000000;
  margin-bottom: 10px;
}

.theme_btn_yellow {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #fe7e00;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #fe7e00;
  margin-bottom: 10px;
}

.theme_btn_yellow:active {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #fe7e00;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #fe7e00;
  margin-bottom: 10px;
}

.theme_btn_yellow:focus {
  font-weight: 700;
  padding: 0 60px;
  border-radius: 70px;
  height: 55px;
  line-height: 55px;
  background: #fe7e00;
  color: #fff !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  border: 2px solid #fe7e00;
  margin-bottom: 10px;
}

.theme_btn_yellow:hover {
  background: #000000;
  color: #fff !important;
  border: 2px solid #000000;
}

.chart01 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}

.chart01 li {}

.chart01 li span {
  width: 45px;
  margin: 0 12px;
  display: block;
}

.chart01 li:nth-child(2) span {
  background: #0f02ff;
  height: 140px;
}

.chart01 li:first-child span {
  background: #00b050;
  height: 103px;
}

.chart01 li:last-child span {
  background: #fb1417;
  height: 190px;
}

.chart01 li i {
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}

.chart01 li i b {
  display: block;
  opacity: 0.5;
  color: #000;
  font-weight: 500;
}

.chart01 li:first-child i {
  color: #00b050;
  font-weight: bolder;
}

.chart01 li:last-child i {
  color: #fb1417;
  font-weight: bolder;
}

.chart01 li:nth-child(2) i {
  color: #0f02ff;
  font-weight: bolder;
}

.ErrorPage {
  background-color: #efefef;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.ErrorPage h1 {
  font-size: 46px;
  font-family: "Poppins", sans-serif;
  color: #3d2570;
  line-height: 56px;
  font-weight: 700;
}

.box-circuit .detail_all {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  border: 2px solid #80808052;
  border-radius: 10px;
  overflow: hidden;
}

.box-circuit .detail_all img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.box-circuit h6 {
  font-weight: 700;
  color: #212529;
}

.box-circuit p {
  color: grey;
  font-weight: 600;
}

.welcom-box {
  height: 270px;
}

.welcom-box video {
  height: 100%;
}

button.btn-expand {
  border: none;
  font-size: 12px;
  font-weight: 500;
  background: none;
  padding: 0;
}

button.btn-expand-read {
  border: none;
  font-size: 12px;
  font-weight: 500;
  background: none;
  padding: 0;
  color: lightgray;
}

.change-level-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}

.change-level-info-wrapper h6 {
  font-size: 15px;
}

.level-change-btn-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.level-change-btn {
  bottom: 0;
  height: 40px;
  background: #fb1417;
  border: 0px;
  color: #fff;
  font-family: var(--content-family);
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 1;
  transition: 0.7s ease;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 40px;
  padding: 0px;
}

.level-change-btn:hover {
  background: #000;
  transition: 0.7s ease;
}

.detail_text h6,
.detail_text p,
.detail_hour p {
  color: #000000;
}

select.form-control:not([size]):not([multiple]) {
  height: auto !important;
}

input.form-control.custom-age::placeholder {
  padding: 0 25px 0 36px;
  color:grey
}


img.poster-image {
  width: 100%;
  max-height: 354px;
  border-radius: 15px;
}

.class-hidden {
  visibility: hidden !important;
}

.poster-video-container {
  position: relative;
}

.poster-video-container.transition-active {
  transition: opacity 0.5s ease;
  /* Adjust the duration and easing as needed */
}

.poster-video-container img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.5s ease;
  /* Adjust the duration and easing as needed */
}

.poster-video-container video {
  opacity: 0;
  transition: opacity 0.5s ease;
  /* Adjust the duration and easing as needed */
}

.poster-video-container.transition-active img {
  opacity: 0;
}

.poster-video-container.transition-active video {
  opacity: 1;
}

.show-icon:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' id='calendar'%3E%3Cpath fill='%237e7e7e' d='M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z'/%3E%3C/svg%3E%0A");
  width: 20px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 14px;
  background-position: left top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

button.changeLevelBtn {
  border: none;
  text-decoration: underline;
  background-color: unset;
  display: block;
  padding: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: black;
}

button.changeLevelBtn:hover {
  color: red;
}

.empty-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.Educational-info {
  margin-top: 1rem;
}

.Educational-info ul {
  padding-left: 2rem;
  list-style: inherit;
}

.Educational-info ul li {
  list-style: inherit;
}

li.dropdown_user.mobile-profile .dropdown {
  text-align: center;
  display: none;
}

ul.menu_top li.mobile-profile {
  display: none;
}

.timer_step {
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
}

.timer_step li:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #f1f2f6;
  position: absolute;
  border-radius: 50%;
  top: 0px;
}

.timer_step:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #f1f2f6;
  top: 7px;
}

.home_0011 {
  background-color: #b02318;
}

.home_0011 h2 {
  font-weight: 700;
  color: #fff;
}

.home_0011 p {
  font-weight: 600;
  color: #fff;
  font-size: 26px;
  margin-bottom: 10px;
}

.home_0011 p.p-small {
  font-size: 18px;
}

.home_0011 .Body_type {
  background-color: #fff;
  border: 3px solid #000;
  /* width: 50%; */
  box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
  padding: 10px 10px;
}

.Body_type p {
  color: #000;
}

span.type_span {
  font-weight: 700;
  font-size: 30px;
}

.body_content a {
  color: #fff;
  font-weight: 700;
}

.body_list span {
  font-size: 23px;
  color: white;
  font-weight: 700;
}

.body_list h4 {
  color: white;
}

.body_content h3 {
  color: #fff;
  font-weight: 700;
}

.angela {
  position: relative;
}

.angela p {
  position: absolute;
  right: 272px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffffb0;
}

i.fa.fa-check.text-white {
  margin-right: 5px;
}

.slide-content ul {
  padding: 5px 15px;
}

.slide-content ul li {
  list-style: disc;
}

.fullscreen-modal {
  max-width: 100%;
  width: 100%;
  margin: 0;
}

div#fullScreenModal {
  max-width: 100%;
  width: 100%;
  margin: 0;
}

div#fullScreenModal .modal-content {
  /*min-height: 100vh;*/
  width: 100%;
  border-radius: 15px;
}

.fullscreen-modal-body {
  padding: 0px;
  border-radius: 15px;
}

@media (orientation: landscape) {
  div#fullScreenModal .modal-content {
    overflow: hidden;
    /* Show scrollbar in landscape mode */
  }

  .fullscreen-modal-body video {
    height: 98vh;
  }
}

.accordion-button:not(.collapsed) {
  color: #fb1417;
  background-color: unset;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 50 50' height='50px' id='Layer_1' version='1.1' viewBox='0 0 50 50' width='50px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect fill='none' height='50' width='50'/%3E%3Cline fill='none' stroke='%23fb1417' stroke-miterlimit='10' stroke-width='4' x1='9' x2='41' y1='25' y2='25'/%3E%3C/svg%3E") !important;
}

.accordion-button::after {
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 50 50' height='50px' id='Layer_1' version='1.1' viewBox='0 0 50 50' width='50px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect fill='none' height='50' width='50'/%3E%3Cline fill='none' stroke='%23000000' stroke-miterlimit='10' stroke-width='4' x1='9' x2='41' y1='25' y2='25'/%3E%3Cline fill='none' stroke='%23000000' stroke-miterlimit='10' stroke-width='4' x1='25' x2='25' y1='9' y2='41'/%3E%3C/svg%3E") !important;
}

.accordion-button {
  border: unset;
  border-radius: 0;
  padding: 1rem 0.25rem;
}

.accordion-item:last-of-type {
  border-bottom: unset;
}

button.accordion-button h6 {
  margin-bottom: 0px !important;
}

.accordion-item {
  background-color: #fff;
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.display-none {
  display: none;
}

.level-change-link.dropdown button {
  width: 100%;
  background: unset;
  border: unset;
  padding: 0;
}

.level-change-link.dropdown button::after {
  display: none;
}

.level-change-link-menu.dropdown-menu.show {
  width: 280px;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  background-color: unset;
  border: unset;
  box-shadow: unset;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: unset !important;
}

a.edu.popup-youtube {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a.edu.popup-youtube svg {
  width: 23px;
  height: 23px;
  /* margin-bottom: 4px; */
  margin: 9px 10px 5px;
}

a.edu.popup-youtube h6 {
  margin-bottom: 1px;
}

a.edu.popup-youtube a {
  margin-bottom: 1px;
}

.bannerImgWrap {
  height: 100%;
}

a.edu.popup-youtube-custom {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a.edu.popup-youtube-custom svg {
  width: 35px;
  height: 30px;
  margin: 0;
}

a.edu.popup-youtube-custom h6 {
  margin-bottom: 1px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
}

a.edu.popup-youtube-custom a {
  margin-bottom: 1px;
}

a.edu.popup-youtube h5 {
  margin-bottom: 0px;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

.btn-finish {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* #videoModal{
    max-width: 40vw;
    width: 40vw    
} */

/* .custom-modal-style {
    width: 897px;
    height: 802px;
} */

.human-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  margin-left: 15%;
}

.suggestion-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 83%;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 36px;
}

.suggestion-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestion-list li:hover {
  background-color: #f0f0f0;
}

.custom-width-vh {
  /* height: calc(100vh - 80px); */
  height: auto;
}

.title-header {
  height: 50px;
}

.common-box-height .library-nav {
  height: 95%;
}

.common-box-height .library-nav .library-img-container {
  height: 100%;
}

.common-box-height .library-nav .library-img-container .library-img-container-inner {
  height: 55px;
}

.common-box-height .library-img-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.programs {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.program-box-height {
  min-height: 416px;
}

.program-card {
  border: 3px solid #ccc;
  padding: 25px;
  cursor: pointer;
  width: 200px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
}

.program-card a {
  color: #000;
}

.program-card.active {
  border-color: red;
}

/* .program-card:hover {
  border-color: red;
} */

@media only screen and (max-width: 1400px) {
  .common-box-height {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .common-box-height .library-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* object-fit: contain; */
    object-position: center;
  }
}

@media only screen and (max-width: 992px) {
  .common-box-height {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .common-box-height .library-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* object-fit: contain; */
    object-position: center;
  }
}

@media only screen and (max-width: 767px) {
  .common-box-height {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .common-box-height .library-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* object-fit: contain; */
    object-position: center;
  }
}

@media only screen and (max-width: 992px) {
  .custom-width-vh {
    height: auto;
  }

  /* .title-header {
        height: auto;
    } */
}

.custom-height {
  height: 76%;
  overflow-y: auto;
}

.recomended-products img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.title-one {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.title-two {
  font-size: 12px;
  color: #000;
}

.slick-slider {
  /* overflow: hidden; */
  cursor: pointer;
}

.slick-slider img {
  /* background: #2e8555; */
  /* color: #fff; */
}

.slider-main-block-details {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.slider-main-block-details-inner-main {
  width: 80%;
  padding: 0px 15px;
}

.slider-main-block-details-inner-thumb {
  width: 20%;
}

.slider-main-block-details-inner-thumb .library-inner-thumb {
  align-items: center;
  background: #fff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 15px;
  height: 100px;
}

.slider-main-block-details-inner-thumb .library-inner-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: transparent;
}

.slider-main-block-details-inner-thumb .library-inner-thumb .video-icon {
  text-align: center;
}

.slider-main-block-details-inner-thumb .library-inner-thumb .video-icon svg {
  font-size: 20px !important;
}

.library-inner-thumb-two {
  width: 100%;
  padding: 25px;
  border: 1px solid #f1f2f6;
  height: 500px;
}

.library-inner-thumb-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: transparent;
}

.slider-main-block-details-inner-thumb .slick-track {
  height: auto !important;
}

.transbtn {
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  border-width: 0px;
}

/* #productDetails .modal-content {
  border-radius: 50px;
} */

.modal-product-desc,
.modal-product-comment {
  text-align: justify;
}

#productDetails .modal-content .content-box {
  margin-top: 40px;
}

.slider-main-block-details-inner-thumb .library-inner-thumb .video-icon {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #c13033;
  border-radius: 50%;
  margin: 0 auto;
}

.slider-main-block-details-inner-thumb .library-inner-thumb .video-icon svg {
  fill: #fff;
}

.padd-ing-diff {
  padding: 0px 20px 0 0;
}

/* Play Button */
.play-button {
  background-color: #00000000;
  border-width: 0px;
}

.custom-search-category {
  margin-top: 15px;
}

.custom-search-category .library-img-box {
  height: 100px;
}

.detail-videos {
  position: relative;
}

.video-player .detail-play-button {
  background-color: #00000000;
  border-width: 0px;
  position: absolute;
  top: 35%;
  left: 45%;
}

.detail-controls-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.body_map_image {
  position: relative;
}

.body_map_image img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.body_map_image img.lazyloaded {
  display: block;
}

.area:hover img {
  display: block;
  transform: scale(1.1);
  border: 2px solid #ff0000;
  /* Optional: Add a border or any other styling */
}

.hide {
  display: none;
}

/* .shoulder:hover {
  fill: red;
}
.neck:hover {
  fill: red;
} */

.body-part {
  transition: fill 0.3s ease;
}

.body-part.hovered {
  fill: red;
}

.svg-container {
  position: relative;
}

.hover-text {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  pointer-events: none;
  white-space: nowrap;
  transform: translate(-50%, -100%);
}

.hover-text::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

#chart01 {
  display: flex !important;
  justify-content: center;
  align-items: flex-end !important;
  margin-bottom: 20px;
}

#chart01 li {
  border: none !important;
}

#chart01 li span {
  width: 45px;
  margin: 0 12px;
  display: block;
}

#chart01 li:nth-child(2) span {
  background: #0f02ff;
  height: 140px;
}

#chart01 li:first-child span {
  background: #00b050;
  height: 103px;
}

#chart01 li:last-child span {
  background: #fb1417;
  height: 190px;
}

#chart01 li i {
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}

#chart01 li i b {
  display: block;
  opacity: 0.5;
  color: #000;
  font-weight: 500;
}

#chart01 li:first-child i {
  color: #00b050;
}

#chart01 li:last-child i {
  color: #fb1417;
  font-weight: bolder;
}

#chart01 li:nth-child(2) i {
  color: #0f02ff;
  font-weight: bolder;
}

.equipment-text {
  font-family: Calibri;
  /* font-family: Calibri, sans-serif; */
  font-weight: 100;
  color: grey;
}

/* #user-weight-modal .weight-radio-btn .btn-check:checked + .btn {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

#user-weight-modal .weight-radio-btn .btn {
  width: 100px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500;
}

#user-weight-modal .weight-radio-btn .btn-outline-success:hover,
#user-weight-modal .weight-radio-btn .btn-outline-warning:hover,
#user-weight-modal .weight-radio-btn .btn-outline-danger:hover {
  background-color: #007bff;
  color: white;
} */

.error_msg {
  color: red;
  font-weight: bold;
}

.page-title-terms {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title-terms .ql-video {
  display: block;
  /* Ensures no inline spacing issues */
  width: 100%;
  /* Use full width of the container */
  height: 100%;
  /* Ensure it scales proportionally */
  aspect-ratio: 16 / 9;
  /* Maintain 16:9 aspect ratio */
  object-fit: cover;
  /* Ensures content fills the iframe */
  border: none;
  /* Removes border around iframe */
}

.empty-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46.1vh;
}

.custom-facebook-button {
  background-color: white;
  /* Facebook blue */
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #4267B2;
  border-radius: 5px;
  padding: 7px 14px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-facebook-button:hover {
  background-color: white;
  /* Darker Facebook blue */
  transform: scale(1.05);
  /* Slight zoom effect */
}

.custom-facebook-button:active {
  background-color: #29487d;
  /* Even darker blue for active state */
  transform: scale(1);
  /* Reset zoom */
}
.modal-backdrop.show {
  opacity: 0.4;
}

.custom-modal-style .modal-dialog {
  max-width: 1000px;
}
.custom-modal-style .modal-dialog .modal-content {
  border-radius: 20px;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
}
.custom-modal-style .modal-dialog .modal-content .modal-header {
  padding: 30px 20px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.6);
  align-items: center;
}
.custom-modal-style .modal-dialog .modal-content .modal-header h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #000;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .close {
  top: -10px;
  right: -10px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #c00000;
  padding: 0;
  opacity: 1;
  position: absolute;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
}
.custom-modal-style .modal-dialog .modal-content .modal-header .close span {
  font-size: 0px;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('data:image/svg+xml,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.8659 11.0007L21.2659 2.60065C21.7992 2.06732 21.7992 1.26732 21.2659 0.733984C20.7325 0.200651 19.9325 0.200651 19.3992 0.733984L10.9992 9.13399L2.59919 0.733984C2.06585 0.200651 1.26585 0.200651 0.73252 0.733984C0.199186 1.26732 0.199186 2.06732 0.73252 2.60065L9.13252 11.0007L0.73252 19.4007C0.465853 19.6673 0.33252 19.934 0.33252 20.334C0.33252 21.134 0.865853 21.6673 1.66585 21.6673C2.06585 21.6673 2.33252 21.534 2.59919 21.2673L10.9992 12.8673L19.3992 21.2673C19.6659 21.534 19.9325 21.6673 20.3325 21.6673C20.7325 21.6673 20.9992 21.534 21.2659 21.2673C21.7992 20.734 21.7992 19.934 21.2659 19.4007L12.8659 11.0007Z" fill="white"/></svg>');
  width: 23px;
  height: 23px;
}
.custom-modal-style .modal-dialog .btn-group .icon_bar svg {
  width: 50px;
  height: 50px;
}
.custom-modal-style .modal-dialog .dropdown-menu {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: none;
  padding: 0px;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .dropdown-menu h4 {
  font-size: 22px;
  line-height: 26px;
  padding: 10px;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .dropdown-menu .btn_chart {
  padding: 10px;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .dropdown-menu .btn_chart .theme_btn1 {
  margin-top: 15px;
  padding: 12px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 10px;
  background-color: #c00000;
  font-weight: 700;
  min-width: 100%;
  min-width: 100%;
  height: auto;
  border: none;
  text-transform: capitalize;
  margin: 0px;
}
.custom-modal-style .modal-dialog .modal-content .modal-header .dropdown-menu .btn_chart .theme_btn1:hover {
  background-color: #000;
}
.modal-body-custom {
  padding: 30px 20px;
}
.modal-body-custom .test_one {
  padding: 0px;
}
.modal-body-custom .test_one .tab-content {
  padding: 0px;
  box-shadow: none;
}
.modal-body-custom .test_one .tab-content .exc_p {
  padding-top: 14px;
  border-top: none;
}
.modal-body-custom .test_one .tab-content ol li {
  color: rgba(0, 0, 0, 0.4);
}
.modal-body-custom .test_one .tab-content .exc_p.note-block-red ol li {
  color: #fb1417;
}
.modal-body-custom.pad-block-modal.modal-body {
  padding: 30px 20px;
}
.modal-body-custom.pad-block-modal .box_state {
  padding: 0px;
}
.modal-body-custom.pad-block-modal .box_state .checkbox_custom {
  margin: 0px 0px 25px;
}
.modal-body-custom.pad-block-modal .box_state .checkbox_custom input {
  display: none;
}
.modal-body-custom.pad-block-modal .box_state .checkbox_custom label {
  position: relative;
  font-family: 'Mulish', serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 45px;
  margin: 0px;
}
.modal-body-custom.pad-block-modal .box_state .checkbox_custom label::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #c00000 !important;
}
.modal-body-custom.pad-block-modal .box_state .checkbox_custom input[type='checkbox']:checked+label::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 6px;
  background-image: url('data:image/svg+xml,<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 21" width="27" height="21"><defs><image width="27" height="21" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAMAAACAAGUXAAAAAXNSR0IB2cksfwAAAZVQTFRFAgICAAAA/wAAqhke8B8o/yEr5SIqBAQEAAAAuhgf/yEp8Rsi+x0l1iAmAAAAAQEBSSQk9iAo8Rsi6Rsh/R4myxwkGRkZFxcXihYd/yEq5xsi6xsh/B8nsBYfAAAApRgc/h8n6Bsi7Bsi+h4lyR0mtCMt/R4m6Rsi7Boi9h0k5CEoAAAAcxkZ/h8m6Rwi7Bsh8xwj6hsnQEBA/yAp6Bsi7Bsi7Roh4yUsYhQU/yEt6Bsh7Bsi6Boh9SUtuxog/CEq1hwk7yQt6Boi7Bsi/yIq7ycw8hsi7Bsi6xsi9R0l1Roi6SMq8Rkg7Boh+x4mhxYeAgICBwcHzRsj/B0l/h8nXRcXuyQo9hsi8hsi5SEoDw8Pxhoj6xoi6hoi4B4lVRUV/x0m6xwj6Roh8SAoEBAQzxkf9Rwk7Bsi/R8m/yEq6xsi/yEp6iEq6Roh5xsh/x8n6Bsh9B0kwBoh6xsh6hoh5iAnDQ0Nvh4m7xoh/CAp6xsi6xoh2hwlwhge7Rsh6Roi/yAo1h0kRhcX9hwj7x8q9CUsohIZ9dR17AAAAId0Uk5TAAAAWdf/pAAEa/37/1gBAAfl/fb/QAAAFf/59/86Aw/V9/n/PROs7/r/TQIY8Pf8/nAE//j8+60N+Pj++t5h9WTN+v//h/37+/9hi/v8/yIBAGL//wtA//x0ADr6+rgM//n61gBb/v3/+v//vvn5+vn/Rfv7wgBe/fj9+1p+/Pr/aQv/09hHS5UQ6wAAAV1JREFUeJxl0T1Iw0AUB/D3F/qBeqkIKggRhEIhjoq2QqGDDg6iuHV2UHRwURBcHNx0FhR0cHFx0E2HDoKDtVi/IINYQYI4dBBzDk1V4uXy0Za84T7y4+7eewHBBpFNToC8gBM/CPZOROtiiFlxaYSEqRCZRErNooSYibVJsYg3zvXApqqY+yTBcG6m1hiQUjcobElJ3waFTHPvq3AKWcy1G+Yb42NFzeAy+bRDVx4Jy6Lyl7oUe8ZzwFdXQTX8DkwCvxHgTSeWxWc38KLPnM2eSpvD3SBFOqHzNF6jKh518TV/LE2Zvs58UD9uR4DnFA7lY/MH7nvauLjHHN5bLI2eT+3GLNE+0zfGl1GODwFPyXacOHk0jFRjKX4vlrUMdmR+Nta2/drVPEpi+d5RdtNXTL8+8Vf4Ooq1XLX3yK2s2YitoDABbHo1t9rq/gIeLsjrlY0mI9rCRtCq4Nw/KoppOHc6By0AAAAASUVORK5CYII="/></defs><style></style><use id="Background" href="%23img1" x="0" y="0"/></svg>');
  width: 25px;
  height: 19px;
  background-size: contain;
}

.modal-body-custom.pad-block-modal .box_state .form-group textarea{
    min-height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px; 
}
.modal-body-custom .theme-common-btn-big {
  padding: 14px 10px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 10px;
  background-color: #c00000;
  font-weight: 700;
  min-width: 230px;
  border-color: transparent;
  margin: 0px;
  font-family: 'Poppins', serif;
  text-align: center;
  display: f;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 auto;
  height: auto;
}
.modal-body-custom .theme-common-btn-big:hover {
    background-color: #000;
  }
.modal-body-custom .theme-common-btn-big.black-btn {
  background-color: #000;
}
.custom-modal-style.small-modal-common .modal-dialog {
  max-width: 700px;
}
.custom-modal-style.small-modal-common .img-product-img-modal {
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}
.custom-modal-style.small-modal-common .img-product-img-modal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.custom-modal-style .modal-custom-title {
  font-size: 24px;
  line-height: 30px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}
.custom-modal-style .modal-custom-title-desc {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.custom-modal-style .modal-product-comment.modal-custom-title-desc.color-blue {
  color: #0342B6;
  font-style: italic;
font-family: 'Mulish', serif;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above the form */
}
.instructions-text {
  color : #c4c4c4 !important;
}
.text-placement {
  position: absolute;
}
.top-left {
  top: 8px;
  left: 35px;
}

.top-right {
  top: 8px;
  right: 35px;
}

.bottom-left {
  bottom: 4px;
  left: 30px;
}

.bottom-right {
  bottom: 4px;
  right: 25px;
}

.top-modal-inner-custom .library-inner-thumb-two {
  padding: 0px;
  border: none;
}
.top-modal-inner-custom .library-inner-thumb-two p.mt-2 {
  font-size: 24px;
  line-height: 36px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 600;
}
.top-modal-inner-custom .library-inner-thumb-two .video-player {
  height: 400px;
  width: 100%;
  border-radius: 20px;
}
.top-modal-inner-custom .library-inner-thumb-two .video-player video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px !important;
}
.top-modal-inner-custom .library-inner-thumb-two .controls {
  border-radius: 20px !important;
}
.related-video-inner-modal {
  padding-top: 30px;
}
.muscles-modal .modal-body .related-video.related-video-inner-modal h5 {
  background-color: transparent;
  color: #000;
  padding: 0px;
  border: none;
  font-size: 24px;
  line-height: 36px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 600;
}
.flex-block-video {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  margin: 0px -15px;
}
.muscles-modal .modal-body .flex-block-video .right-video {
  width: 33.33%;
  padding: 0px 15px;
  display: block !important;
}
.muscles-modal .modal-body .flex-block-video .right-video .video-player {
  width: 100% !important;
  height: 170px;
  border-radius: 20px !important;
}
.muscles-modal .modal-body .related-video .flex-block-video .right-video .video-player video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px !important;
}
.muscles-modal .modal-body .related-video .flex-block-video .right-video .video-player i {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.muscles-modal .modal-body .related-video .flex-block-video .right-video .modal-title{
  text-align: center;
  padding-top: 20px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  color: #000;
}
.table {
  background-color: white !important;
}

.table th,
.table td {
  background-color: white !important;
}

.table thead th {
  border-bottom: none !important;
}


.modal-body-custom .changes-level-modal .changes-level-modal-img {
  width: 100%;
  height: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}
.modal-body-custom .changes-level-modal .changes-level-modal-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modal-body-custom .changes-level-modal p {
  /* padding-top: 20px; */
  text-align: center;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  margin-top: 25px;
}
.modal-body-custom .position-block-inner  {
  margin-top: 20px;
}
.modal-body-custom .position-block-inner .position-block-inner-body {
  padding-top: 15px;
}
.modal-body-custom .position-block-inner .position-block-inner-body h3 {
  text-align: left;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.modal-body-custom .position-block-inner .position-block-inner-body ul {
  list-style: disc;
  padding-left: 16px;
}
.modal-body-custom .position-block-inner .position-block-inner-body ul li {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  padding-bottom: 5px;
}
.center-block-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}
.center-block-btn .common-btn-all-front {
  margin-top: 15px;
  padding: 14px 10px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 10px;
  background-color: #c00000;
  font-weight: 700;
  min-width: 230px;
  border-color: transparent;
  margin: 0px;
}
@media screen and (max-width: 1399px) {
  .custom-modal-style .modal-dialog .modal-content .modal-header {
        padding: 15px;
  }
  .custom-modal-style .modal-dialog .modal-content {
    border-radius: 10px;
  }
  .custom-modal-style .modal-dialog .modal-content .modal-header h4 {
    font-size: 26px;
    line-height: 26px;
  }
  .modal-body-custom {
    padding: 15px;
  }
  .top-modal-inner-custom .library-inner-thumb-two p.mt-2 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .muscles-modal .modal-body .related-video.related-video-inner-modal h5 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .custom-modal-style .modal-dialog .modal-content .modal-header .close {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 1199px) {
  .custom-modal-style .modal-dialog .modal-content .modal-header .close {
    top: -10px;
    right: 12px;
  }
  .flex-block-video {
    margin: 0px -10px;
  }
    .muscles-modal .modal-body .related-video .right-video {
      padding: 0px 10px;
    }
}
@media screen and (max-width: 991px) {
  .muscles-modal .modal-body .related-video .right-video .modal-title h6 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
  .muscles-modal .modal-body .flex-block-video .right-video .video-player {
    height: 130px;
    border-radius: 10px !important;
  }
  .muscles-modal .modal-body .related-video .flex-block-video .right-video .modal-title {
    padding-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }
  .muscles-modal .modal-body .related-video .flex-block-video .right-video .video-player video {
    border-radius: 10px !important;
  }
  .muscles-modal .modal-body .flex-block-video .right-video {
    width: 50%;
  }
    .custom-modal-style.small-modal-common .img-product-img-modal {
      height: 200px;
      margin-bottom: 15px;
    }
  .custom-modal-style .modal-custom-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .custom-modal-style .modal-custom-title-desc {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) {
  .custom-modal-style .modal-dialog .modal-content .modal-header h4 {
    font-size: 22px;
    line-height: 24px;
  }
  .modal-content {
    max-width: 96% !important;
  }
  .custom-modal-style .modal-dialog .modal-content .modal-header .close {
    top: 7px;
    right: -3px;
  }
  .top-modal-inner-custom .library-inner-thumb-two .video-player {
    height: auto;
    border-radius: 10px;
  }
  .top-modal-inner-custom .library-inner-thumb-two .video-player video {
    border-radius: 10px !important;
  }
  .muscles-modal .modal-body .custom-video .video-player i {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .muscles-modal .modal-body .flex-block-video .right-video .video-player {
    height: 100px;
  }
  .custom-modal-style.small-modal-common .img-product-img-modal {
    height: 170px;
    margin-bottom: 15px;
  }

  .custom-modal-style .modal-custom-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .custom-modal-style .modal-custom-title-desc {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 16px;
  }
}
.set-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 30px;
  font-weight: bold;
}

.set-label {
  margin-right: 10px;
}

.set-number {
  cursor: pointer;
  color: black;
  transition: color 0.2s ease-in-out;
  margin-right: 5px;
}

.set-number.active {
  color: red;
  text-decoration: underline;
}

/* .set-number:hover {
  color: red;
} */

.ql-font-arial {
  font-family: Arial, sans-serif !important;
}
.ql-font-times-new-roman {
  font-family: "Times New Roman", serif !important;
}
.ql-font-courier-new {
  font-family: "Courier New", monospace !important;
}
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif !important;
}
.ql-font-georgia {
  font-family: Georgia, serif !important;
}
.ql-font-helvetica {
  font-family: Helvetica, sans-serif !important;
}


.ql-size-10px {
  font-size: 10px !important;
}

.ql-size-12px {
  font-size: 12px !important;
}

.ql-size-14px {
  font-size: 14px !important;
}

.ql-size-16px {
  font-size: 16px !important;
}

.ql-size-18px {
  font-size: 18px !important;
}

.ql-size-20px {
  font-size: 20px !important;
}

.ql-size-24px {
  font-size: 24px !important;
}

.ql-size-28px {
  font-size: 28px !important;
}

.ql-size-32px {
  font-size: 32px !important;
}

/* .custom-input::placeholder {
  color: #D3D3D3; 
  opacity: 1; 
} */