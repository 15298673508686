@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
h1,h2,h3,h4,h5,h6 {
  font-family: "Poppins", serif;
  margin: 0px;
  font-weight: 500;
  color: #000;
}
p {
  font-family: "Mulish", serif;
  margin: 0px;
}
.muscles-modal {
  .modal-body {
    .controls {
      height: 29px;
      background: #4d4d4d;
      opacity: 0.9;
      border-radius: unset;

      .controls-wrap {
        .play-pause-button {
          width: 24px;
          height: 24px;
        }
        .play-pause-button-custom {
          width: 50px;
          height: 50px;
        }
      }
    }

    .custom-video {
      height: auto;

      .video-player {
        position: relative;

        video {
          border-radius: unset;
        }

        i {
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          top: 43%;
          left: 46.3%;

          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .related-video {
      h5 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #c00;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        margin-bottom: 15px;
      }

      .right-video {
        display: flex;
        margin-bottom: 15px;

        .video-player {
          width: 50%;
          margin-right: 10px;
          position: relative;
          video {
            border-radius: unset !important;
          }

          i {
            position: absolute;
            top: 29%;
            left: 40%;

            svg {
              width: 25px;
              height: 25px;
            }
          }
        }

        .modal-title {
          width: 50%;

          h6 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }

        .controls {
          background: transparent;
          box-shadow: none;
          padding: 0;
          height: 26px;

          .controls-wrap {
            display: flex;
            background-color: #797979;
            width: 90px;
            padding: 0 6px;
            margin-left: auto;

            .left-wrapper {
              .play-pause-button {
                display: none;
              }

              .video-duration {
                font-size: 13px;
              }
            }

            .right-wrapper {
              display: none;
            }
          }

          .progress-bar {
            display: none;
          }
        }
      }
    }
  }
}
