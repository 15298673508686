@media (min-width: 1501px) and (max-width: 1920px) {
  .bodytype_002 h4 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  h2.pain {
    margin-top: 30px;
  }

  h1 {
    font-size: 45px;
  }

  .bodytype_001 p {
    font-size: 19px;
    line-height: 33px;
  }

  .change_level_001 h4 {
    font-size: 20px;
  }

  .timer_box ul li h6 {
    font-size: 16px;
  }

  .timer_box ul li p {
    font-size: 16px;
  }

  .box_banner_inner p {
    font-size: 16px;
  }

  .assestmentd1_001 h3 {
    font-size: 21px;
  }

  ul.breadcrumb.main li a {
    font-size: 12px;
  }

  .nav_week a {
    font-size: 14px;
  }

  .box_p.shadow_box h6 {
    font-size: 15px;
  }

  .modal_test .modal-content .modal-body h4 {
    font-size: 19px;
  }

  .box_banner_inner h5 {
    font-size: 22px;
  }

  .education a {
    font-size: 12px;
  }

  ul.menu_top li {
    /* display: block !important; */
    /* width: 100%; */
  }

  ul.menu_top {
  }

  .logo_signup {
    padding-bottom: 9px;
    margin-bottom: 9px;
  }

  .box_register {
    padding: 4px 40px 0px 40px;
  }

  .form-1 {
    height: 50px;
    line-height: 50px;
  }

  .checkbox_custom label,
  .box_register p {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .checkbox_custom label:before {
    padding: 6px;
  }

  .checkbox_custom input:checked + label:after {
    top: 3px;
    left: 5px;
    width: 4px;
    height: 9px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .name_ic:after {
    width: 17px;
    height: 17px;

    top: 16px;
  }

  .password_ic:after,
  .phone_ic:after,
  .email_ic:after {
    width: 16px;
    height: 16px;
    top: 17px;
  }

  .form-1 {
    font-size: 12px;
  }

  .welcome_001 {
    height: 400px;
  }

  .home_002 {
    height: 719px;
  }

  ul.menu_top li a {
    font-size: 14px;
  }

  .home_006,
  .home_005 {
    height: 460px;
  }

  h2 {
    font-size: 45px;
  }

  .home_009 p {
    font-size: 20px;
    line-height: 30px;
  }

  .home_0011 p {
    font-size: 20px;
  }

  .home_0011 p.p-small {
    font-size: 17px;
  }

  .home_0012 p {
    font-size: 20px;
  }

  ul.list_pain li p {
    font-size: 17px;
  }

  .home_0013 h5 {
    font-size: 20px;
  }

  ul.menu_top li {
    /* display: block !important; */
    /* width: 100%; */
  }

  ul.menu_top {
  }

  .logo_signup {
    padding-bottom: 9px;
    margin-bottom: 9px;
  }

  .box_register {
    padding: 4px 40px 0px 40px;
  }

  .form-1 {
    height: 50px;
    line-height: 50px;
  }

  .checkbox_custom label,
  .box_register p {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .checkbox_custom label:before {
    padding: 6px;
  }

  .checkbox_custom input:checked + label:after {
    top: 7px;
    left: 5px;
    width: 4px;
    height: 9px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .name_ic:after {
    width: 17px;
    height: 17px;

    top: 16px;
  }

  .password_ic:after,
  .phone_ic:after,
  .email_ic:after,
  .show-icon:after {
    width: 16px;
    height: 16px;
    top: 17px;
  }

  .form-1 {
    font-size: 12px;
  }

  .welcome_001 {
    height: 490px;
  }

  .home_002 {
    height: 719px;
  }

  ul.menu_top li a {
    /* font-size: 14px; */
    font-size: 13px;
  }

  .home_006,
  .home_005 {
    height: 390px;
  }

  h2 {
    font-size: 55px;
  }

  .home_009 p {
    font-size: 20px;
    line-height: 30px;
  }

  .home_0011 p {
    font-size: 20px;
  }

  .home_0011 p.p-small {
    font-size: 17px;
  }

  .home_0012 p {
    font-size: 20px;
  }

  ul.list_pain li p {
    font-size: 17px;
  }

  .home_0013 h5 {
    font-size: 20px;
  }
  .social-profile .wrapper {
    /*max-width: 130px;*/
    max-height: 130px;
  }

  .home_0013 .slide-content {
    padding: 20px 30px 20px 30px;
  }
  span.info {
    font-size: 16px;
    line-height: 24px;
  }
  .exercise-info-box p {
    font-size: 16px;
    margin-bottom: 0px;
  }
  p.info-p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  a.exercise-box.nav-link {
    padding: 15px;
  }
}
@media (min-width: 1501px) and (max-width: 1600px) {
  .home_004 .img-cover {
    min-width: 221px;
    max-width: 221px;
  }
  .home_004 .slide-content {
    height: 300px;
    padding: 10px 10px 10px 20px;
  }
  .home_004 h6 {
    font-size: 16px;
  }
  .home_004 p {
    font-size: 14px;
  }
  .home_0013 .slide-content {
    padding: 10px 20px 10px 20px;
  }

  .testimonials .img-cover {
    min-width: 202px;
    max-width: 202px;
  }

  span.info {
    font-size: 16px;
    line-height: 24px;
  }
  .exercise-info-box p {
    font-size: 16px;
    margin-bottom: 0px;
  }
  p.info-p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  a.exercise-box.nav-link {
    padding: 15px;
  }
}
@media (min-width: 1281px) and (max-width: 1500px) {
  .assestmenttimwe_001 {
    padding: 25px 0 15px 0;
  }
  .timer_step {
    height: 80px;
  }
  .bodytype_002 h4 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  h2.pain {
    margin-top: 30px;
  }

  h1 {
    font-size: 45px;
  }

  .bodytype_001 p {
    font-size: 19px;
    line-height: 33px;
  }

  .change_level_001 h4 {
    font-size: 20px;
  }

  .timer_box ul li h6 {
    font-size: 12px;
  }

  .timer_box ul li p {
    font-size: 12px;
  }

  .box_banner_inner p {
    font-size: 16px;
  }

  .assestmentd1_001 h3 {
    font-size: 21px;
  }

  ul.breadcrumb.main li a {
    font-size: 12px;
  }

  .nav_week a {
    font-size: 14px;
  }

  .box_p.shadow_box h6 {
    font-size: 15px;
  }

  .modal_test .modal-content .modal-body h4 {
    font-size: 19px;
  }

  .box_banner_inner h5 {
    font-size: 22px;
  }

  .education a {
    font-size: 12px;
  }
  .main_head {
    justify-content: space-around;
  }
  ul.menu_top li {
    padding-left: 10px;
    padding-right: 10px;
  }

  ul.menu_top {
  }

  .logo_signup {
    padding-bottom: 9px;
    margin-bottom: 9px;
  }

  .box_register {
    padding: 4px 40px 0px 40px;
  }

  .form-1 {
    height: 50px;
    line-height: 50px;
  }

  .checkbox_custom label,
  .box_register p {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .checkbox_custom label:before {
    padding: 6px;
  }

  .checkbox_custom input:checked + label:after {
    top: 8px;
    left: 5px;
    width: 4px;
    height: 9px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .name_ic:after {
    width: 17px;
    height: 17px;

    top: 16px;
  }

  .password_ic:after,
  .phone_ic:after,
  .email_ic:after {
    width: 16px;
    height: 16px;
    top: 17px;
  }

  .form-1 {
    font-size: 12px;
  }

  .welcome_001 {
    height: 400px;
  }

  .home_002 {
    height: 719px;
  }

  ul.menu_top li a {
    font-size: 14px;
  }

  .home_006,
  .home_005 {
    /*height: 460px;*/
    height: auto;
  }

  .home_007,
  .home_008 {
    height: 460px;
  }

  h2 {
    font-size: 55px;
  }
  .home_004 h2 {
    font-weight: 800;
  }

  .home_009 p {
    font-size: 20px;
    line-height: 30px;
  }

  .home_0011 p {
    font-size: 20px;
  }

  .home_0011 p.p-small {
    font-size: 17px;
  }

  .home_0012 p {
    font-size: 20px;
  }

  ul.list_pain li p {
    font-size: 17px;
  }

  .home_0013 h5 {
    font-size: 20px;
  }

  .home_004 .img-cover {
    min-width: 189px;
    max-width: 189px;
    max-height: 258px;
  }
  .home_004 h6 {
    font-size: 16px;
  }
  .home_004 p {
    font-size: 14px;
  }

  .testimonials .img-cover {
    min-width: 172px;
    max-width: 172px;
  }
  .home_004 .slide-content {
    height: 258px;
    padding: 10px 10px 10px 20px;
  }

  span.info {
    font-size: 16px;
    line-height: 24px;
  }
  .exercise-info-box p {
    font-size: 16px;
    margin-bottom: 0px;
  }
  p.info-p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  a.exercise-box.nav-link {
    padding: 15px;
  }
}

@media (min-width: 1199px) and (max-width: 1280px) {
  .assestmenttimwe_001 {
    padding: 25px 0 15px 0;
  }
  .timer_step {
    height: 70px;
  }
  .bodytype_002 h4 {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  .home_004 .img-cover {
    min-width: 179px;
    max-width: 179px;
  }

  .home_004 h6 {
    margin-bottom: 2px;
    font-size: 14px;
  }
  .home_004 p {
    font-size: 14px;
  }

  .testimonials .img-cover {
    min-width: 162px;
    max-width: 162px;
  }
  .home_004 .slide-content {
    height: 243px;
    padding: 10px 10px 10px 20px;
  }
}
@media (min-width: 1101px) and (max-width: 1365px) {
  .assestmenttimwe_001 {
    padding: 25px 0 15px 0;
  }
  .timer_step {
    height: 80px;
  }
  .img-cont {
    height: 300px;
  }
  .edit-img-cont {
    height: 300px;
  }
  .edit-profile {
    font-size: 16px;
  }
  a.edit-profile svg {
    height: 20px;
    width: 20px;
  }
  ul.profile-info {
    padding: 10px 20px;
  }
  .profile-info h4 {
    font-size: 16px;
    line-height: 24px;
  }
  ul.profile-info li {
    margin-bottom: 2rem;
  }
  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .bodytype_002 h4 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  h2.pain {
    margin-top: 20px;
  }

  h1 {
    font-size: 50px;
  }

  .bodytype_001 p {
    font-size: 22px;
    line-height: 35px;
  }

  section.bodytype_002 {
    padding: 50px 0;
  }

  .checkmark.main_check h6 {
    font-size: 14px;
  }

  .change_level_002 {
    padding: 40px 0;
  }

  .checkmark.main_check p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .timer strong {
    font-size: 19px;
  }

  .tabs_listview .right_list {
    padding-left: 10px;
  }

  .tabs_listview .left_list .box_levelimg1 {
    height: 166px;
    width: 166px;
  }

  .tabs_listview .right_list p,
  .tabs_listview .right_list ul.steps li {
    font-size: 12px;
    margin-bottom: 3px;
    line-height: 18px;
  }

  .tabs_listview .title_box h6 {
    padding-right: 0;
  }

  .tabs_listview a.nav-link .icon_level.text-right {
    bottom: 15px !important;
  }

  .grid_001 {
    padding-bottom: 40px;
  }

  .tabs_listview .right_list ul.steps li p {
    margin-bottom: 0px;
  }

  /*end listview*/
  .box_banner_inner p {
    font-size: 16px;
  }

  .title_box h6 {
    /* font-size: 14px; */
    font-size: 13px;
  }

  .icon_level svg {
    width: 20px;
    height: 20px;
  }

  .left_tabs li a {
    padding: 10px;
  }

  .box_test h4.mb-20 {
    margin-bottom: 10px;
  }

  h6 {
    font-size: 15px;
  }

  .icon_bar svg {
    width: 23px;
  }

  .list_exs li p {
    font-size: 13px;
  }

  .box_week h6 {
    font-size: 17px;
  }

  .box_week p {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  .box_date p {
    line-height: 16px;
    font-size: 13px;
    text-align: center;
  }

  .nav_week a {
    font-size: 13px;
  }

  .date_week li p {
    padding: 7px 0;
    width: 49px;
    font-size: 13px;
    line-height: 16px;
  }

  /*end gridview*/
  .checkbox_custom input:checked + label:after {
    top: 1px;
  }

  .welcome_001 {
    height: 341px;
    background-size: cover;
  }

  .box_banner_inner h1 {
    font-size: 40px;
  }

  .box_banner_inner h5 {
    font-size: 20px;
  }

  ul.breadcrumb.main li a {
    font-size: 12px;
  }

  .assestmentd1_001 h3 {
    font-size: 18px;
  }

  .assestmentd1_001 {
    padding: 22px 0;
  }

  /*.box_video {*/
  /*    height: 271px;*/
  /*}*/

  .education a {
    font-size: 11px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .box_test h4 {
    margin-bottom: 20px;
  }

  .checkbox_custom label {
    font-size: 12px;
  }

  .form-group.checkbox_custom {
    margin-bottom: 10px;
    margin-top: 6px;
  }

  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 50px;
    height: 45px;
    line-height: 45px;

    font-size: 16px;
  }

  .test1_001 {
    padding-bottom: 70px;
  }

  /*end assestment-test1 */
  h2 {
    font-size: 40px;
  }

  .home_0012 {
    padding: 70px 0;
  }

  .home_002 {
    height: 500px;
  }

  .home_005,
  .home_006,
  .home_007,
  .home_008,
  .home_0013 {
    height: 450px;
    position: relative;
  }
  .home_005,
  .home_006,
  .home_007,
  .home_008,
  .home_0012 {
    height: 350px;
    position: relative;
  }
  section.home_0012 {
    height: 640px;
  }

  .home_0011 {
    background-size: cover;
  }

  .home_0011 p,
  .home_0012 p {
    font-size: 20px;
  }

  ul.list_pain li p {
    font-size: 16px;
  }

  .home_009 p {
    font-size: 18px;
    line-height: 30px;
  }

  .home_003 {
    padding: 70px 0;
  }

  .home_001 {
    height: 80vh;
    background-size: cover;
  }

  .box_register {
    padding: 5px 20px;
  }

  .form_signup {
    padding: 0 10px;
  }

  .box_register p {
    font-size: 12px;
    line-height: 20px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .box_register h4 {
    font-size: 20px;
  }

  .register {
    background-position: right top !important;
    background-size: cover !important;
  }

  .main_head {
    justify-content: space-around;
  }
  ul.menu_top li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .box_banner {
    padding: 20px;
  }

  ul.menu_footer li a {
    font-size: 12px;
  }

  .help p {
    font-size: 10px;
  }

  .footer h6 {
    font-size: 14px;
  }

  .bx_footer p {
    font-size: 11px;
  }

  .bx_footer {
    padding-right: 60px;
  }

  ul.cards li div a {
    font-size: 12px;
  }

  section.bottom_footer p,
  section.bottom_footer p a {
    font-size: 12px;
  }

  .help p a {
    font-size: 12px;
    word-break: break-all;
  }

  ul.menu_top li a {
    font-size: 12px;
  }

  ul.menu_top li a.btn-1 {
    height: 30px;
    line-height: 30px;
    padding: 0 25px !important;
  }

  .timer_box ul li h6,
  .timer_box ul li p {
    font-size: 11px;
  }

  .social-profile .wrapper {
    max-width: 100px;
    max-height: 100px;
  }
  .home_004 h2 {
    font-weight: 800;
    font-size: 52px;
  }
  .home_004 h6 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home_004 p {
    font-size: 14px;
  }
  .home_004 .slide-content {
    height: 260px;
    padding: 5px 5px 5px 10px;
    width: 100%;
  }
  .home_004 .img-cover {
    min-width: 205px;
    max-width: 205px;
    max-height: 270px;
    height: 260px;
  }
  span.info {
    font-size: 14px;
    line-height: 18px;
  }
  .exercise-info-box p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  p.info-p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  .assestmenttimwe_001 {
    padding: 25px 0 15px 0;
  }
  .timer_step {
    height: 80px;
  }

  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .bodytype_002 h4 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  h2.pain {
    margin-top: 20px;
  }

  h1 {
    font-size: 39px;
  }

  .bodytype_001 p {
    font-size: 18px;
    line-height: 32px;
  }

  section.bodytype_002 {
    padding: 50px 0;
  }

  .img_box {
    height: 210px;
  }

  ul.time_list li span {
    font-size: 11px;
    margin-bottom: 3px;
    width: 50%;
  }

  .checkmark.main_check h6 {
    font-size: 14px;
  }

  .change_level_002 {
    padding: 40px 0;
  }

  .checkmark.main_check p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .timer_step li p {
    font-size: 10px;
  }

  .timer strong {
    font-size: 16px;
  }

  .timer_box ul li h6,
  .timer_box ul li p {
    font-size: 11px;
  }

  .tabs_listview .right_list {
    padding-left: 10px;
  }

  .tabs_listview .left_list .box_levelimg1 {
    height: 143px;
    width: 143px;
  }

  .tabs_listview .right_list p,
  .tabs_listview .right_list ul.steps li {
    font-size: 12px;
    margin-bottom: 3px;
    line-height: 18px;
  }

  .tabs_listview .title_box h6 {
    padding-right: 0;
  }

  .tabs_listview a.nav-link .icon_level.text-right {
    bottom: 15px !important;
  }

  .grid_001 {
    padding-bottom: 40px;
  }

  .tabs_listview .right_list ul.steps li p {
    margin-bottom: 0px;
  }

  /*end listview*/

  span.info {
    font-size: 12px;
    line-height: 18px;
  }
  .exercise-info-box p {
    font-size: 12px;
    margin-bottom: 0px;
  }
  p.info-p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }
  .title_box h6 {
    /* font-size: 14px; */
    font-size: 13px;
  }

  .icon_level svg {
    width: 20px;
    height: 20px;
  }

  .left_tabs li a {
    padding: 6px;
  }

  .box_test h4.mb-20 {
    margin-bottom: 10px;
  }

  h6 {
    font-size: 15px;
  }

  .icon_bar svg {
    width: 23px;
  }

  .list_exs li p {
    font-size: 13px;
  }

  .box_week h6 {
    font-size: 17px;
  }

  .box_week p {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  .box_date p {
    line-height: 16px;
    font-size: 13px;
    text-align: center;
  }

  .nav_week a {
    font-size: 13px;
  }

  .date_week li p {
    padding: 7px 0;
    width: 49px;
    font-size: 13px;
    line-height: 16px;
  }

  /*end gridview*/
  .checkbox_custom input:checked + label:after {
    top: 1px;
  }

  .welcome_001 {
    height: 341px;
    background-size: cover;
  }

  .box_banner_inner h1 {
    font-size: 40px;
  }

  .box_banner_inner h5 {
    font-size: 20px;
  }

  ul.breadcrumb.main li a {
    font-size: 12px;
  }

  .assestmentd1_001 h3 {
    font-size: 18px;
  }

  .assestmentd1_001 {
    padding: 22px 0;
  }

  /*.box_video {*/
  /*    height: 271px;*/
  /*}*/

  .education a {
    font-size: 11px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .box_test h4 {
    margin-bottom: 20px;
  }

  .checkbox_custom label {
    font-size: 12px;
  }

  .form-group.checkbox_custom {
    margin-bottom: 10px;
    margin-top: 6px;
  }

  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 50px;
    height: 45px;
    line-height: 45px;

    font-size: 16px;
  }

  .test1_001 {
    padding-bottom: 70px;
  }

  /*end assestment-test1 */
  h2 {
    font-size: 36px;
  }

  .home_0012 {
    padding: 70px 0;
  }

  .home_002 {
    height: 500px;
  }

  .home_005,
  .home_006,
  .home_007,
  .home_008,
  .home_0013 {
    height: 270px;
    position: relative;
  }

  .home_0011 {
    background-size: cover;
  }

  .home_0011 p,
  .home_0012 p {
    font-size: 20px;
  }

  ul.list_pain li p {
    font-size: 16px;
  }

  .home_009 p {
    font-size: 18px;
    line-height: 30px;
  }

  .home_003 {
    padding: 70px 0;
  }

  .home_001 {
    height: 80vh;
    background-size: cover;
  }

  .box_register {
    padding: 5px 20px;
  }

  .form_signup {
    padding: 0 10px;
  }

  .box_register p {
    font-size: 12px;
    line-height: 20px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .box_register h4 {
    font-size: 20px;
  }

  .register {
    background-position: right top !important;
    background-size: cover !important;
  }

  ul.menu_top li {
    padding-left: 13px;
    padding-right: 13px;
  }

  .box_banner {
    padding: 20px;
  }

  ul.menu_footer li a {
    font-size: 12px;
  }

  .help p {
    font-size: 10px;
  }

  .footer h6 {
    font-size: 14px;
  }

  .bx_footer p {
    font-size: 11px;
  }

  .bx_footer {
    padding-right: 60px;
  }

  ul.cards li div a {
    font-size: 12px;
  }

  section.bottom_footer p,
  section.bottom_footer p a {
    font-size: 12px;
  }

  .help p a {
    font-size: 12px;
    word-break: break-all;
  }

  ul.menu_top li a {
    font-size: 13px;
  }

  ul.menu_top li a.btn-1 {
    height: 30px;
    line-height: 30px;
    padding: 0 25px !important;
  }

  [role="progressbar"] {
    --size: 180px;
  }

  .social-links h3 {
    font-size: 24px;
    line-height: 16px;
  }
  .btn-follow {
    font-size: 14px;
    max-height: 35px;
    padding: 7px;
    margin-top: 7px;
  }
  .social-profile .wrapper {
    max-width: 90px;
    max-height: 90px;
  }
  ul.links-icons li {
    padding: 6px 9px 9px;
  }
  ul.links-icons li svg {
    font-size: 18px;
  }
  .kit h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .home_004 h6 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home_004 p {
    font-size: 12px;
  }
  .home_004 .slide-content {
    height: 186px;
    padding: 5px 5px 5px 10px;
  }
  .home_004 .img-cover {
    min-width: 135px;
    max-width: 135px;
    max-height: 186px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .home_004 h6 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home_004 p {
    font-size: 10px;
  }
  .home_004 .slide-content {
    height: 186px;
    padding: 5px 5px 5px 10px;
  }
  .home_004 .img-cover {
    min-width: 124px;
    max-width: 124px;
    max-height: 270px;
    height: 185px;
  }

  .testimonials .Profile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home_004 .swiper {
    padding-bottom: 0px;
  }

  .theme-btn-link-black {
    height: 25px;
    line-height: 25px;
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .img-cont,
  .edit-img-cont {
    height: 250px;
  }
  ul.profile-info {
    padding: 0px 0px;
  }
  .profile-info h4 {
    font-size: 16px;
    line-height: 24px;
  }
  ul.profile-info li {
    margin-bottom: 1rem;
  }
  .edit-profile {
    font-size: 14px;
    gap: 5px;
    align-items: flex-start;
  }
  a.edit-profile svg {
    height: 18px;
    width: 18px;
  }
  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .img01 {
    height: 450px;
  }

  .img01 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .bodytype_002 h4 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  h2.pain {
    margin-top: 20px;
  }

  h1 {
    font-size: 39px;
  }

  .bodytype_001 p {
    font-size: 18px;
    line-height: 32px;
  }

  section.bodytype_002 {
    padding: 50px 0;
  }

  .img_box {
    height: 180px;
  }

  ul.time_list li span {
    font-size: 11px;
    margin-bottom: 3px;
    width: 50%;
  }

  .assestmentd1_002 {
    padding-bottom: 30px;
  }

  [role="progressbar"] {
    --size: 180px;
  }

  .box_levelimg {
    height: 130px;
  }

  .checkmark.main_check h6 {
    font-size: 13px;
  }

  .change_level_002 {
    padding: 40px 0;
  }

  .checkmark.main_check p {
    font-size: 11px;
    margin-bottom: 4px;
  }

  .mr-20 {
    margin-right: 1px;
  }

  .form-2 {
    font-size: 11px;
    margin-bottom: 3px;
  }

  .timer_box ul li h6,
  .timer_box ul li p {
    font-size: 9px;
  }

  .timer_box ul li {
    padding: 0 4px;
  }

  .timer strong {
    font-size: 15px;
  }

  .timer_step li p {
    font-size: 9px;
  }

  .tabs_listview .right_list {
    padding-left: 10px;
  }

  .tabs_listview .left_list .box_levelimg1 {
    height: 113px;
    width: 113px;
  }

  .tabs_listview .right_list p,
  .tabs_listview .right_list ul.steps li {
    font-size: 10px;
    margin-bottom: 3px;
    line-height: 13px;
  }

  .tabs_listview .title_box h6 {
    padding-right: 0;
  }

  .tabs_listview a.nav-link .icon_level.text-right {
    bottom: 15px !important;
  }

  .grid_001 {
    padding-bottom: 40px;
  }

  .tabs_listview .right_list ul.steps li p {
    margin-bottom: 0px;
  }

  /*end listview*/
  .mt-30 {
    margin-top: 10px;
  }

  .box_levelimg1 {
    height: 120px;
  }

  .title_box h6 {
    font-size: 12px;
  }

  .icon_level svg {
    width: 17px;
    height: 17px;
  }

  .left_tabs li a {
    padding: 6px;
  }

  .box_test h4.mb-20 {
    margin-bottom: 10px;
  }

  .box_p.shadow_box h6 {
    font-size: 13px;
  }

  h6 {
    font-size: 15px;
  }

  .icon_bar svg {
    width: 23px;
  }

  .list_exs li p {
    font-size: 12px;
  }

  .box_week h6 {
    font-size: 16px;
  }

  .box_week p {
    font-size: 11px;
  }

  p {
    font-size: 14px;
  }
  span.info {
    font-size: 12px;
    line-height: 18px;
  }
  .box_date p {
    line-height: 16px;
    font-size: 13px;
    text-align: center;
  }

  .nav_week a {
    font-size: 13px;
  }

  .date_week li p {
    padding: 7px 0;
    width: 49px;
    font-size: 13px;
    line-height: 16px;
  }

  /*end gridview*/
  ul.breadcrumb.main li a {
    font-size: 12px;
  }

  .assestmentd1_001 h3 {
    font-size: 18px;
  }

  .assestmentd1_001 {
    padding: 22px 0;
  }

  /*.box_video {*/
  /*    height: 200px;*/
  /*}*/

  .education a {
    font-size: 11px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .box_test h4 {
    margin-bottom: 20px;
  }

  .checkbox_custom label {
    font-size: 12px;
  }

  .form-group.checkbox_custom {
    margin-bottom: 10px;
    margin-top: 6px;
  }

  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 40px;
    height: 45px;
    line-height: 45px;

    font-size: 16px;
  }

  .test1_001 {
    padding-bottom: 70px;
  }

  /*end assestment-test1 */
  .welcome_001 {
    height: 270px;
    background-size: cover;
  }

  .box_banner_inner h1 {
    font-size: 30px;
  }

  .box_banner_inner h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .box_banner_inner p {
    font-size: 16px;
  }

  .img_video {
    height: 220px;
  }

  ul.menu_top li {
    padding-left: 8px;
    padding-right: 8px;
  }

  .box_banner {
    padding: 20px;
  }

  ul.menu_footer li a {
    font-size: 10px;
  }

  .help p {
    font-size: 10px;
  }

  .footer h6 {
    font-size: 12px;
  }

  .bx_footer p {
    font-size: 11px;
  }

  .bx_footer {
    padding-right: 60px;
  }

  ul.cards li div a {
    font-size: 12px;
  }

  section.bottom_footer p,
  section.bottom_footer p a {
    font-size: 12px;
  }

  .help p a {
    font-size: 10px;
    word-break: break-all;
  }

  ul.menu_top li a {
    font-size: 11px;
  }

  ul.menu_top li a.btn-1 {
    height: 30px;
    line-height: 30px;

    padding: 0 15px !important;
  }

  .box_register {
    padding: 5px 20px;
  }

  .form_signup {
    padding: 0 10px;
  }

  .box_register p {
    font-size: 12px;
    line-height: 20px;
  }

  .logo_signup svg {
    width: 130px;
    height: 50px;
  }

  .box_register h4 {
    font-size: 20px;
  }

  .register {
    background-position: center top !important;
    background-size: cover !important;
  }

  .home_001 {
    height: 60vh;
    background-size: cover;
  }

  h2 {
    font-size: 36px;
  }

  .home_002 {
    height: auto;
    padding: 70px 0;
  }

  .home_002 .h-100 {
    height: inherit !important;
  }

  .home_003 {
    padding: 60px 0;
  }

  .home_005,
  .home_006 {
    height: auto;
  }

  .home_007 {
    height: 400px;
  }

  .home_008 {
    height: 310px;
    margin-top: -40px;
  }

  .home_009 p {
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
  }

  .pb-50 {
    padding-bottom: 30px;
  }

  .home_0011 p {
    font-size: 20px;
  }

  .home_0011 {
    padding: 30px 0;
    background-size: cover;
  }

  .home_0012 {
    padding: 60px 0;
  }

  ul.list_pain li p {
    font-size: 17px;
  }

  .home_0012 p {
    font-size: 18px;
  }

  .home_0013 {
    height: 450px;
  }

  .social-profile .wrapper {
    max-width: 90px;
    max-height: 90px;
  }
  .social-links h3 {
    font-size: 22px;
  }
  .btn-follow {
    font-size: 12px;
    max-height: 40px;
    padding: 8px;
    margin-top: 4px;
  }
  ul.links-icons li {
    padding: 7px 10px 10px;
  }
  .kit h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .timer_step:after {
    left: 60px;
    content: "";
    width: 154%;
    position: absolute;
    height: 2px;
    background: #f1f2f6;
    top: 7px;
  }
  .assestmenttimwe_001 {
    padding: 15px 0 15px 0;
  }
  .timer_step {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    height: 80px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .angela p {
    position: static;
    text-align: right;
    font-size: 20px !important;
  }

  .home_0011 p {
    font-size: 12px;
  }

  .body_list h4 {
    font-size: 14px;
  }

  .body_list span {
    font-size: 12px;
  }
  span.type_span {
    font-size: 17px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .box_test h4.mb-20 {
    margin: 10px 0px;
  }
  .accordion {
    padding: 0;
  }
  .accordion-section {
    padding: 60px 30px;
  }
  .faq-heading {
    padding: 10px 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .accordion-section .contents {
    display: none;
    padding: 10px 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .faq-heading::after {
    right: 8px;
    width: 16px;
    height: 3px;
    margin-top: -1px;
  }
  .faq-heading::before {
    right: 15px;
    width: 3px;
    height: 15px;
    margin-top: -7px;
  }
  .img-cont {
    height: 300px;
  }
  .edit-img-cont {
    height: 300px;
  }
  .update-profile {
    margin-bottom: 1rem;
  }
  ul.profile-info {
    padding: 0px 0px;
  }
  .profile-info h4 {
    font-size: 16px;
    line-height: 24px;
  }
  ul.profile-info li {
    margin-bottom: 1rem;
  }
  .edit-profile {
    font-size: 14px;
  }
  a.edit-profile svg {
    height: 22px;
    width: 22px;
  }
  .email_ic:after,
  .password_ic:after,
  .phone_ic:after,
  .name_ic:after {
    top: 13px;
  }

  [role="progressbar"]::after {
    font-size: 26px;
    border: 35px solid #f9f9f9;
    border-radius: 50%;
  }

  .modal_test .modal-content .modal-body h4 {
    margin-bottom: 20px;
    line-height: 25px;
    font-size: 15px;
  }

  ul.breadcrumb.main li:last-child:after {
    display: none;
  }

  .box_video:before {
    /*display: none;*/
  }

  div#video_controls_bar {
    display: none;
  }

  .img01 {
    /* width: 110px; */
    height: 260px;
    margin: 0 auto 40px;
  }

  .img01 img.img-fluid {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h2.pain {
    margin-top: 15px;
    margin: 0;
  }

  .bodytype_002 h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .bodytype_002 a.theme_btn1 {
    margin-bottom: 20px;
  }

  section.bodytype_001 {
    text-align: center;
  }

  .bodytype_001 p {
    font-size: 18px;
    line-height: 30px;
  }

  h1 {
    font-weight: 800;
    font-size: 35px;
  }

  ul.time_list li span {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .weekly {
    padding-left: 4px;
  }

  .nav_week {
    justify-content: center;
  }

  .suggested li:last-child {
    margin-left: 20px;
  }

  .checkmark.main_check h6 {
    margin-top: 20px;
  }

  .form-1 {
    height: 45px;
    line-height: 45px;
  }

  .status_checkout .modal-dialog.modal-dialog-centered {
    min-width: auto;
  }

  .box_state {
    padding: 0 50px;
  }

  ul.rating li label {
    margin: 0 2px;
  }

  label.container-checkbox11 span.checkmark.main_check1 p {
    font-size: 10px;
  }

  .container-checkbox11 .checkmark {
    padding: 4px 0;
  }

  .form-2 {
    font-size: 9px;
  }

  .timer strong {
    font-size: 14px;
  }

  .timer_step li:before {
    bottom: 32px;
    z-index: 3;
  }

  .timer_step:after {
    bottom: 39px;
    translate: 25%;
  }

  .assestmenttimwe_001 {
    padding: 25px 0 25px 0;
  }

  .timer_step li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 22px 0 0 0;
  }

  .timer_step {
    overflow-y: auto;
    justify-content: initial;
    padding-bottom: 11px;
  }

  .timer_box ul li h6,
  .timer_box ul li p {
    font-size: 9px;
  }

  .timer_box ul li svg {
    width: 16px;
    height: 16px;
  }

  .timer_box ul {
    padding: 10px 0;
    height: auto;
  }

  .mr-20 {
    margin-right: 2px;
  }

  .exc_p p {
    font-size: 12px;
  }

  .border-t.position-relative {
    padding-top: 30px;
  }

  .tabs_listview .left_list {
    width: 100%;
  }

  .left_tabs.tabs_listview .box_levelimg1 {
    width: 100%;
    height: 140px;
  }

  .tabs_listview a.nav-link {
    flex-wrap: wrap;
  }

  .tabs_listview .right_list {
    padding-left: 0px;
    width: 100%;
  }

  .tabs_listview .right_list p {
    font-size: 12px;
  }

  .tabs_listview .right_list ul.steps li p,
  .tabs_listview .right_list ul.steps li {
    margin-bottom: 3px;

    font-size: 12px;
  }

  /*end listview*/
  ul.nav.nav-tabs.row.left_tabs {
    padding: 0px 0px 15px;
  }

  .left_tabs .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: -5px;
    margin-bottom: 20px;
  }

  .left_tabs .slick-dots li {
    margin: 0 3px;
  }

  .left_tabs .slick-dots li button {
    font-size: 0;
    border: 0;
    background: #9f9f9f;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
  }

  .left_tabs .slick-dots li.slick-active {
  }

  .left_tabs .slick-dots li.slick-active button {
    background: #fb1417;
  }

  .box_date {
    padding: 10px 0px 10px 10px;
  }

  .box_week h6 {
    font-size: 16px;
  }

  .box_week p {
    font-size: 11px;
  }

  .box_week {
    padding: 15px;
    position: relative;
  }

  .nav_week a {
    font-size: 13px;
  }

  .left_tabs li a {
    padding: 10px;
  }

  .icon_level svg {
    width: 20px;
    height: 20px;
  }

  .title_box h6 {
    /* font-size: 14px; */
    font-size: 13px;
  }

  h6 {
    font-size: 15px;
  }
  span.info {
    font-size: 14px;
    line-height: 18px;
  }
  .exercise-info-box p {
    font-size: 14px;
    margin-bottom: 0px;
  }
  p.info-p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .box_date p {
    font-size: 11px;
    line-height: 15px;
    text-align: center;
  }

  .date_week li p {
    font-size: 11px;
    width: 39px;
    padding: 8px 0;
    line-height: 15px;
  }

  .assestmentd1_001 h3 {
    font-size: 20px;
  }

  ul.breadcrumb.main {
    padding: 13px 0;
  }

  .mobile_setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown_user {
    padding-right: 30px;
  }

  li.dropdown_user.hide_mobile {
    /* display: none; */
  }

  span.icon_mobile {
    display: block;
    position: absolute;
    right: 15px;
    top: 6px;
    color: #fff;
    font-size: 20px;
  }

  .overflow {
    overflow: hidden;
  }

  .show_mobile {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
  }

  .hide_mobile {
    /*display: none;*/
    left: -10000px;
    transition: 1s ease;
    position: absolute !important;
    height: 100% !important;
    transition: all 1s;
  }

  .show_mobile_menu {
    position: absolute !important;
    left: 0 !important;
    height: 100% !important;
    top: 0 !important;
    background: #000000;
    z-index: 99 !important;
    padding-top: 50px;
    display: block;
  }

  .register {
    height: auto;
    padding: 20px 0;
    background-position: left top !important;
    background-size: cover !important;
  }

  .box_register p {
    font-size: 11px;
    line-height: 18px;
  }

  .box_register h4 {
    font-size: 18px;
  }

  .box_register {
    text-align: center;
    padding: 5px 10px;
  }

  .form_signup {
    padding: 0 0px;
  }

  .box_register {
    height: auto;
    min-height: auto;
    width: 100% !important;
    display: flex;
  }

  .register .h-100 {
    height: initial !important;
  }

  .border-t {
    position: initial;

    width: 100%;
  }

  h4 {
    font-size: 14px;
  }

  .test_videobox input {
    width: 20px;
    height: 20px;
  }
  /*.box_video {*/
  /*    height: 170px;*/

  /*}*/

  .education a {
    font-size: 12px;
  }

  .box_test h4 {
    margin-bottom: 20px;
  }

  .form-group.checkbox_custom {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .box_test {
    margin-top: 20px;
    padding: 14px;
  }

  grid_001 {
    padding-bottom: 70px;
  }

  .nav_week.justify-content-end {
    justify-content: center !important;
  }

  .icon_bar svg {
    width: 25px;
  }

  .list_exs li p {
    margin: 0;
    font-size: 12px;
  }

  .test1_001 {
    padding-bottom: 90px;
    padding-top: 20px;
  }

  .checkbox_custom label {
    font-size: 12px;
    margin-bottom: unset;
  }

  .checkbox_custom label:before {
    padding: 6px;
  }

  .checkbox_custom input:checked + label:after {
    top: 2px;
    left: 5px;
    width: 4px;
    height: 9px;
    border-width: 0 1px 1px 0;
  }

  ul.breadcrumb.main li a {
    font-size: 10px;
  }

  ul.breadcrumb.main li {
    padding-right: 10px;
    padding-left: 10px;
  }

  .img_video {
    height: 180px;
  }

  .box_banner_inner h1 {
    font-size: 21px;
  }

  .box_banner_inner h5 {
    font-size: 15px;
  }

  .box_banner_inner p {
    font-size: 12px;
  }

  .box_banner_inner {
    padding: 20px;
    height: 160px;
    width: 320px;
  }

  .welcome_001 {
    height: 220px;
    background-size: cover;
  }

  .home_001 {
    height: 230px;
    background-size: cover;
  }

  .home_002 {
    height: 220px;
    background-position: center center;
    background-size: cover;
  }

  .home_003 {
    padding: 40px 0;
  }

  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 30px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
  }

  .img001 {
    /*display: none;*/
  }

  .home_003 h2 {
    margin-bottom: 20px;
  }

  .home_004 {
    padding: 60px 40px 50px 40px;
  }

  .profile {
    padding-bottom: 0px;
  }

  .home_004 video {
    width: 100% !important;
  }

  .home_005:before,
  .home_005:after,
  .home_006:before,
  .home_006:after,
  .home_007:before,
  .home_008:after,
  .home_0013:before {
    display: none;
  }

  .home_005:after {
    width: 100%;
  }

  .home_005 {
    padding-bottom: 30px;
    padding-top: 30px;
    height: auto;
    position: relative;
  }

  .home_005 video {
    padding-left: 0px !important;
    width: 100% !important;
    height: auto;
  }

  .home_006 {
    padding-bottom: 30px;
    height: auto;
    position: relative;
  }

  .show_mob {
    display: block;
    z-index: 999;
    position: relative;
  }

  .home_005 h2 {
    padding-left: 0;
    padding-top: 20px;
  }

  .home_005 .h-100,
  .home_006 .h-100,
  section.home_007 .h-100,
  .home_008 .h-100,
  section.home_0013 .h-100,
  .home_0013 .h-100 {
    height: initial !important;
  }

  .or1 {
    order: 1;
  }

  .or2 {
    order: 2;
  }

  .home_006 h2 {
    padding-top: 20px;
  }

  .home_007 {
    height: auto;
    /*padding-bottom: 30px;*/
  }

  .home_007 h2 {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .home_007:after {
    width: 100%;
  }

  .home_008 {
    height: auto;
    margin-top: 0;
    padding: 40px 0;
  }

  h2 {
    font-size: 22px;
  }

  .home_004 h2 {
    font-size: 42px;
  }
  .home_008 h2 {
    padding-top: 20px;
  }

  .home_009 p {
    font-size: 16px;
    line-height: 25px;
  }

  .home_009 p br {
    display: none;
  }

  .home_009:before {
    width: 100%;
  }

  .home_009 {
    padding: 40px 0 30px;
    position: relative;
  }

  .home_0011 {
    background-position: left center;
    padding: 50px 0;
    background-size: cover;
  }

  .home_0011 p {
    font-size: 17px;
  }

  .pb-50 {
    padding-bottom: 30px;
  }

  .home_0012 p {
    font-size: 17px;
  }

  ul.list_pain li p {
    font-size: 16px;
  }

  .home_0012 {
    padding: 40px 0;
  }

  .home_0013 h5 {
    font-size: 17px;
    font-weight: 600;
  }

  .home_0013:after {
    width: 100%;
  }

  .home_0013 {
    height: auto;
    position: relative;
    padding: 40px 0;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .menu_footer.two li {
    width: 100%;
  }

  ul.cards {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  section.bottom_footer p {
    text-align: center;
    margin-bottom: 4px;
  }

  .footer h6 {
    margin-top: 50px;
  }

  .bottom_footer {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .bx_footer {
    padding-right: 0;
  }

  .footer_logo {
    width: 50%;
  }

  .footer {
    padding: 60px 10px 10px;
  }
  .footer p,
  .footer a {
    font-size: 12px;
  }
  ul.cards li div a {
    font-size: 12px;
  }
  .logo {
    width: 120px;
  }

  ul.menu_top {
    display: block;
  }

  ul.menu_top li {
    display: block;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    /* margin-bottom: 20px; */
  }

  ul.menu_top li a {
    font-size: 12px;
  }

  ul.menu_top.right_menu li:last-child:before {
    display: none;
  }

  .main_head {
    /* display: block; */
    align-items: center;
  }

  ul.menu_top.right_menu {
    position: initial;
    width: 159px;
    margin: 0 auto;
  }

  li.dropdown_user.hide_mobile.show_mobile_menu {
    position: initial !important;
    display: block !important;
    padding-top: 10px;
    text-align: center;
  }

  header.header {
    padding: 15px 0;
  }

  .h_login svg {
    width: 9px;
  }

  .dropdown_user .dropdown-menu.menu_top1 a {
    color: #000;
    padding: 3px 7px;
    font-size: 10px;
  }

  ul.menu_top {
    padding-left: 0;
  }

  .social-profile {
    padding-left: 4%;
    padding-right: 4%;
  }
  .social-profile .wrapper {
    max-width: 80px;
    max-height: 80px;
  }
  .social-links h3 {
    font-size: 20px;
  }
  .btn-follow {
    font-size: 10px;
    max-height: 40px;
    padding: 8px;
    margin-top: 4px;
  }
  ul.links-icons li {
    padding: 7px 10px 10px;
  }
  .kit h3 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  ul.links-icons li svg {
    font-size: 18px;
  }

  .testimonials {
    padding: 40px 40px;
  }

  .testimonials .wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .testimonials .Profile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home_004 h6 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home_004 p {
    font-size: 12px;
  }
  .home_004 .slide-content {
    height: 186px;
    padding: 5px 5px 5px 10px;
    width: 100%;
  }
  .home_004 .img-cover {
    min-width: 174px;
    max-width: 174px;
    max-height: 270px;
    height: 186px;
  }
  li.dropdown_user.mobile-profile .dropdown {
    text-align: center;
    display: inline-block;
  }
  ul.menu_top li.mobile-profile {
    display: block;
  }

  /*.fullscreen-button{*/
  /*    display: none;*/
  /*}*/

  .pagination .totalpages {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    margin: 0px 12px;
  }
  /*li.dropdown_user.mobile-profile .dropdown a#dropdownMenuButton {*/

  /*    display: flex;*/
  /*}*/

  .body_content h3 {
    font-size: 20px;
  }

  .angela p {
    position: static;
    text-align: right;
    font-size: 20px !important;
  }

  .home_0010 p {
    font-size: 14px !important;
  }

  span.type_span {
    font-size: 20px;
  }

  .body_list h4 {
    padding-top: 20px;
  }

  .body_list .body_span {
    font-size: 18px !important;
  }

  .body_list span {
    font-size: 16px;
  }

  .body_content h3 br {
    display: none;
  }
  .z-in {
    text-align: center;
    margin-bottom: 10px;
  }
  .z-in br {
    display: none;
  }
  div#wrapper1 img {
    width: 100%;
    height: auto;
  }
  .theme_btn1.small_btn {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
  }
  .level-change-link-menu.dropdown-menu.show {
    width: 210px;
    height: 280px;
  }
  .bar_dropdown {
    width: 210px;
  }
}
@media (min-width: 0px) and (max-width: 550px) {
  .display-none {
    display: none;
  }
  .box_week.carousel.slide .carousel-control-prev,
  .box_week.carousel.slide .carousel-control-next {
    display: none;
  }
  .box_video .feel-it-here {
    width: 70px;
    height: 70px;
  }
  .modal-content {
    max-width: 400px;
  }
  .recomended-products h2 {
    font-size: 40px;
  }
  .img-cont {
    height: 233px;
  }
  .edit-img-cont {
    height: 300px;
    margin: auto;
  }
  .update-profile {
    margin: auto;
    margin-bottom: 1rem;
  }
  ul.profile-info {
    padding: 0px 0px;
  }
  .profile-info h4 {
    font-size: 16px;
    line-height: 24px;
  }
  ul.profile-info li {
    margin-bottom: 1rem;
  }
  .edit-profile {
    font-size: 14px;
  }
  a.edit-profile svg {
    height: 22px;
    width: 22px;
  }
  .theme-btn-link-black {
    height: 25px;
    line-height: 25px;
    font-size: 16px;
    margin-left: 1%;
  }
  .social-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .social-profile .wrapper {
    max-width: 80px;
  }

  .content {
    padding: 5px 0px 0px;
  }

  .social-profile {
    border-left: unset;
    border-top: 1px solid #090909;
    padding-top: 10px;

    width: 80%;
  }

  ul.links-icons {
    list-style-type: none;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .kit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonials .img-cover {
    margin-right: 10px;
    max-width: 76px;
  }

  .testimonials h6 {
    font-size: 18px;
  }
  .testimonials h5 {
    font-size: 16px;
  }
  .testimonials p {
    font-size: 13px;
  }

  .home_004 .img-cover {
    margin-right: 0px;
  }

  .profile-info {
    margin-top: 1rem;
  }

  .home_004 .Profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .testimonials .Profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mb-xs-4 {
    margin-bottom: 20px !important;
  }
  /*.timer_step li.active:before {*/
  /*    border: 2px solid #FB1417;*/
  /*    background: #f9f9f9;*/
  /*    display: none;*/
  /*}*/

  /*.timer_step li:before {*/
  /*    display: none;*/
  /*}*/

  /*.timer_step:after {*/
  /*    bottom: 39px;*/
  /*    translate: 0;*/
  /*    display: none;*/
  /*}*/

  /*.timer_step li{*/
  /*    flex: 0 0 100%;*/
  /*    max-width: 100%;*/
  /*    padding: 10px;*/
  /*    cursor: pointer;*/
  /*}*/
  /*.timer_step li p{*/
  /*    width: 100%;*/
  /*}*/
  /*.timer_step li.active p {*/
  /*    text-align: center;*/
  /*    display: block;*/
  /*    margin: 0 auto;*/
  /*    padding: 10px 0;*/
  /*}*/

  /*.timer_step {*/
  /*    overflow-y: scroll;*/
  /*    justify-content: initial;*/
  /*    padding-bottom: 11px;*/
  /*    display: block;*/
  /*    height: 100px;*/
  /*}*/

  .timer_step {
    overflow-x: scroll;
    justify-content: initial;
    padding-bottom: 11px;
    display: flex;
    height: auto;
  }

  .timer_step li {
    flex: 0 0 40%;
    max-width: 40%;
    padding: 8px 0 0 0;
  }

  .timer_step li::after {
    content: "";
    width: 50%;
    position: absolute;
    height: 2px;
    background: #f1f2f6;
    top: 7px;
    z-index: 2;
  }

  .timer_step li:last-child::after {
    width: 0% !important;
  }

  .timer_step:after {
    display: none;
  }
  .assestmenttimwe_001 {
    padding: 10px 0 5px 0;
  }
  .assestmenttimwe_001 p {
    width: 120px;
    position: relative;
    top: 10px;
    left: 7px;
    font-size: 10px;
  }

  span.show-error {
    color: red;
    font-size: 9px;
    line-height: 0px;
  }
  .timer_box ul {
    min-height: 75px;
  }
  p {
    font-size: 12px;
  }
  .border-t.position-relative {
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }
  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 20px;
  }
  .video-player .controls .play-pause-button {
    width: 30px;
    height: 30px;
  }
  .video-player .controls {
    height: 45px;
  }
  .sound-controls {
    transition: unset;
  }
  .sound-controls:hover {
    border-radius: 30px;
    background-color: unset;
  }
  input[type="range"] {
    transition: unset;
  }
  .sound-controls:hover input[type="range"] {
    display: none;
  }
  .footer h6 {
    margin-top: 20px;
  }
  .footer {
    padding: 20px 10px 10px;
  }
}
@media (min-width: 0px) and (max-width: 430px) {
  .change_level_002 {
    padding: 30px 0;
  }
  .box_banner_inner {
    padding: 20px;
    height: 160px;
    width: 260px;
  }
  .home_0010 p {
    font-size: 14px !important;
  }
  .library-img-box {
    height: 146px;
  }

  .detail-box-img {
    height: auto;
  }

  .muscle-banner-recommended {
    height: 200px;
  }

  .trigger-banner-recommended {
    height: 240px;
  }

  h2 {
    text-align: center;
    line-height: 30px;
  }
  .title_box h6 {
    font-size: 12px;
  }
  span.info {
    font-size: 10px;
    line-height: 18px;
  }
  .detail_hour p {
    font-size: 10px;
  }

  .home_004 h2 {
    font-weight: 800;
    font-size: 48px;
    padding: 14px 4px;
  }
  .home_004 .slide-content {
    height: 186px;
    padding: 5px 5px 5px 10px;
    width: 100%;
  }
  .home_004 .img-cover {
    min-width: 184px;
    max-width: unset;
    width: 100%;
    max-height: 250px;
    height: 186px;
    margin: 0;
  }
  .home_004 .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 0px) and (max-width: 380px) {
  .img-cont {
    width: 100%;
    height: 131px;
  }
  .edit-img-cont {
    height: 300px;
  }
  .update-profile {
    margin-bottom: 1rem;
  }

  ul.profile-info {
    padding: 0px 0px;
  }
  .profile-info h4 {
    font-size: 14px;
    line-height: 24px;
  }
  ul.profile-info li {
    margin-bottom: 1rem;
  }
  .edit-profile {
    font-size: 12px;
  }
  a.edit-profile svg {
    height: 20px;
    width: 20px;
  }
  .test_content p {
    font-size: 12px;
    margin-top: 10px;
    font-family: "Calibri";
  }
  ul.list_pain1 li span {
    font-size: 12px;
    margin: 5px;
  }
  .theme_btn1,
  .theme_btn_yellow {
    padding: 0 20px;
    height: 30px;
    line-height: 26px;
    font-size: 12px;
  }
  ul.list_pain1 br {
    display: none;
  }
  .home_004 h6 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .home_004 p {
    font-size: 12px;
  }
  .home_004 .slide-content {
    height: 186px;
    padding: 5px 5px 5px 10px;
    width: 100%;
  }
  .home_004 .img-cover {
    min-width: 184px;
    max-width: unset;
    height: 186px;
    width: 100%;
    max-height: 250px;
    margin: 0;
  }
  .home_004 .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .testimonials .img-cover {
    max-width: 180px;
    min-width: 180px;
    margin-right: 0px;
  }

  .box_date p {
    font-size: 9px;
    line-height: 15px;
  }

  .date_week li p {
    font-size: 11px;
    width: 29px;
    padding: 8px 0;
    line-height: 15px;
  }

  .my_or ul.nav.nav-tabs li.nav-item {
    padding-right: 20px;
  }

  .order_detail .tab-content div.tab-pane .order_detail1 li span {
    width: 100%;
    display: block;
    margin-bottom: 1px;
  }

  .update_pimage .upload_pimg {
    width: 190px;
  }

  .update_pimage .box_img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .main_list_search.list_top_selling_product
    ul.list_top_selling_product
    .bx_img {
    width: 40px;
    height: 28px;
  }

  .main_list_search.list_top_selling_product
    ul.list_top_selling_product
    li:nth-child(1) {
    width: 76px;
  }

  .menu_top li a.btn-1 {
    margin-left: 0;
    font-size: 9px;
    height: 28px;
    line-height: 28px;
  }

  .upload_product .sec1 h6 {
    font-size: 10px;
  }

  h5 {
    font-size: 15px;
  }

  form.top_selling_product.my_equpment01
    ul.list_top_selling_product
    li
    .icon_select
    i {
    display: none;
  }
  .recomended-products h2 {
    font-size: 25px;
  }

  .social-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .social-profile {
    border-left: unset;
    border-top: 1px solid #090909;
    padding-top: 10px;

    width: 80%;
  }

  ul.links-icons {
    list-style-type: none;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .kit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home_004 h2 {
    font-weight: 800;
    font-size: 38px;
    padding: 14px 4px;
  }
  .home_004 {
    padding: 60px 20px 50px 20px;
  }
  .home_004 h5 {
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
  }
}
