@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.woff2") format("woff2"),
    url("Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.woff2") format("woff2"),
    url("Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Light.woff2") format("woff2"),
    url("Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.woff2") format("woff2"),
    url("Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBold.woff2") format("woff2"),
    url("Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraBold.woff2") format("woff2"),
    url("Gilroy-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-ExtraBoldItalic.woff2") format("woff2"),
    url("Gilroy-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Black.woff2") format("woff2"),
    url("Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-RegularItalic";
  src: url("Gilroy-RegularItalic.woff2") format("woff2"),
    url("Gilroy-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Bold.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Bold\ Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("./calibri/Calibri\ Light\ Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
