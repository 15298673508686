@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
:root {
  --primary-color: #1279c9;
  --content-family: "Gilroy";
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #fb1417;
  --success-color: #28a745;
}

html {
}

body,
html {
  font-family: var(--content-family);
  letter-spacing: 0.03em;
  padding-right: 0px !important;
}

.rounded-button {
  border-radius: 20px;
  background-color: #c00000;
  border: none;
  color: white;
}

/* .banner-section {
    height: 100vh;
    background: url(../components/images/bannerimage2.png);
    background-position: center right;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.6;
} */

.nav-link {
  display: block;
  /* padding: .5rem 1rem; */
  color: white;
  border-bottom: 1px solid transparent;
  /* gap: 60px; */
}

.navbar-collapse {
  gap: 60px;
  font-family: "oswald";
  font-weight: 500;
  font-size: 24px;
}

.header-section > .navbar {
  padding: 60px 100px 0 100px;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.nav-link:focus,
.nav-link:hover {
  color: white;
  border-bottom: 1px solid #c00000;
}

.banner-section {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure full viewport height */
    overflow: hidden; /* Hide overflow to prevent scrollbars */
}

.banner-container, .home_002 {
    flex: 1; /* Allow the containers to take up available space */
    /* Hide vertical scrolling for containers */
}

@keyframes scrollAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
.scroll-animation {
    animation: scrollAnimation 5s linear; /* Adjust the duration as needed */
    animation-delay: 5s; /* Adjust the duration as needed */
    animation-fill-mode: forwards; 
}
.text-pain {
  padding: 189px 0 0 100px;
  max-width: 851px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
}
.text-pain h2 {
  color: white;
  font-weight: 600;
  font-size: 110px;
}

.text-pain h2 span {
  color: #ff3d3d;
}

.text-pain {
    opacity: 0;
    transform: translateX(-100%);
    animation: slideInFade 2s ease-out forwards;
}

@keyframes slideInFade {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.text-above-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.slider-section {
  position: relative;
}

.slider-slick img {
  width: 100%;
}

.jt-banner-img {
  position: relative;
}

.jt-banner-img figure {
  height: 100vh;
}

.jt-banner-img::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgb(51, 51, 51), rgb(0, 0, 0));
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background-color: #f7f4f4 !important;
}
@media only screen and (max-width: 575px) {
  .jt-banner-img figure {
    height: 900px;
  }
}

.jt-banner-img figure img {
  width: 100%;
  height: 100%;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-attachment: fixed;
}

.jt-banner-tital {
  position: absolute;
  top: 0;
  max-width: unset;
  margin: 80px auto 0;
  padding: 0 8px;
}

@media only screen and (max-width: 575px) {
  .jt-banner-tital {
    max-width: 800px;
    width: 100%;
    margin-top: 186px;
    padding: 0;
  }
}

@media only screen and (max-width: 575px) {
  .jt-banner-tital h3 {
    font-size: 86px;
    line-height: 111px;
    margin-bottom: 50px;
  }
}

.jt-banner-tital p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 30px;
  padding: 0 12px 0 0;
}

@media only screen and (max-width: 575px) {
  .jt-banner-tital p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .jt-banner-tital p {
    padding: 0;
  }
}

.trans-btn {
  padding: 9px 25px;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.swiper-pagination {
  text-align: left;
  margin-left: 100px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.swiper-pagination-bullet-active {
  background-color: #f5eff0 !important;
}

@media only screen and (max-width: 319px) {
  .swiper-pagination {
    left: 2%;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-pagination {
    left: 16%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .swiper-pagination {
    left: 16%;
  }
}

.swiper-pagination-bullet {
  border: 1px solid #ffffff;
  background-color: transparent;
  opacity: 1;
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  background-color: white;
  width: 15px;
  height: 15px;
}

.jt-banner-img2 {
  position: relative;
}

.jt-banner-img2 figure {
  height: 210px;
}

@media only screen and (max-width: 575px) {
  .jt-banner-img2 figure {
    height: 385px;
  }
}

.jt-banner-img2 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.jt-banner-img2 .jt-banner-tital {
  position: absolute;
  top: 35%;
  left: 40%;
}

@media only screen and (max-width: 319px) {
  .jt-banner-img2 .jt-banner-tital {
    left: 45%;
  }
}

@media only screen and (max-width: 575px) {
  .jt-banner-img2 .jt-banner-tital {
    padding: 0 12px;
    left: 0;
  }
}

.jt-banner-img2 h3 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .jt-banner-img2 h3 {
    font-size: 60px;
    line-height: 82px;
    text-align: left;
  }
}

.jt-banner-img1 {
  max-width: unset;
}

.jt-banner-img1 figure {
  height: 210px;
}

@media only screen and (max-width: 575px) {
  .jt-banner-img1 figure {
    height: 500px;
  }
}

.jt-banner-img1 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.jt-banner-img1 .jt-banner-tital {
  position: absolute;
  top: 0;
  margin: 75px auto 0;
  padding: 0 8px;
}

@media only screen and (max-width: 575px) {
  .jt-banner-img1 .jt-banner-tital {
    margin-top: 153px;
    padding: 0;
  }
}

.jt-banner-img1 h3 {
  font-size: 40px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .jt-banner-img1 h3 {
    font-size: 60px;
    line-height: 82px;
  }
}

.banner-section  .banner-container  .home_000 {
  padding: 80px 100px 50px 100px;
  font-family: "Gilroy";
  width: 100%;
}

.banner-section  .banner-container .main-div {
  justify-content: space-between;
}

.row1 {
  padding: 50px;
}
.fade-out {
    animation: fadeOutAnimation 3s ease-in;
}

@keyframes fadeOutAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.banner-section .banner-container .home_000 h2 {
  font-weight: 600;
  text-align: center;
  color: #130f0f;
  line-height: 80px;
  font-family: "Oswald";
  font-size: 50px;
}
.banner-section .banner-container .home_000 h2 span {
  font-weight: 600;
  color: #d62f2f;
  line-height: 80px;
  font-family: "Oswald";
  font-size: 50px;
}

.banner-section .banner-container .card {
  border: 0px solid #ccc;
  border-radius: 35px;
  padding: 20px;
  margin-top: -150px;
  box-shadow: 0 4px 50px 5px rgba(0, 0, 0, 0.1);
  z-index: 9;
  justify-content: center;
}

.text-center1 {
  text-align: center;
  padding: 0px 63px 0px 63px;
}
