.custom_alert {
    margin: 0px;
    position: fixed;
    top: 5px;
    right: 5px;
    width: 300px;
    z-index: 9999;
    animation: fadeInLeft 1s ease-in-out;
}

.day-underline {
    border-bottom: 3px solid;
}

.exc_p {
    margin-bottom: 10px;
}

.loader_custom span {
    margin: auto;
    display: block;
}

span.show-error {
    color: red;
    font-size: 12px;
}

.modal_test.vedio_modal .modal-dialog {
    max-width: 900px;
}

.modal_test.vedio_modal .modal-content .modal-body.modal-body {
    padding: 0px;
    background: transparent;
    border: 0px;
}

/*.modal_test.vedio_modal .modal-content .close {*/
/*    color: #fff;*/
/*    right: -10px;*/
/*    position: absolute;*/
/*    top: -30px*/
/*}*/

.modal_test.vedio_modal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal_test.vedio_modal .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    position: relative;
}

.modal_test.vedio_modal .modal-content .close {
    color: #000;
    position: absolute;
    top: 5px;
    right: 15px;
}

.modal_test.vedio_modal .modal-content .modal-body.modal-body {
    padding: 20px 20px 20px 20px;
    background: transparent;
    border: 0px;
    border-radius: 0.3rem;
}

.skip-test-error {
    margin-top: -20px;
    margin-bottom: 20px;
}
.exercise-info-box p{
    color: #000000;
}
p.info-p {
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}
span.info {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #FB1417;
}



.pagination{
    display: flex;
    flex: 1 1 auto;
    -webkit-box-pack: end;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    min-height: 56px;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.pagination span {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    margin: 0px 4px;
}

.pagination .totalpages{
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    margin: 0px 24px;
}
select#itemsPerPage {
    cursor: pointer;
    height: 24px;
    max-width: 100%;
    user-select: none;
    padding-left: 8px;
    padding-right: 24px;
    box-sizing: content-box;
    font-size: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    appearance: none;
    direction: ltr;
    flex-shrink: 0;
}

button.button-previous {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
}



button.button-next {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
}



.perpagedropdown {
    position: relative;
    flex-shrink: 0;
    font-size: 14px;
    color: inherit;
    margin-top: 1px;
}

.perpagedropdown svg{
    top: 0px;
    right: 0px;
    color: inherit;
    position: absolute;
    fill: currentcolor;
    width: 24px;
    height: 24px;
    display: inline-block;
    user-select: none;
    pointer-events: none;
}

button.button-next {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.4s ease 0s;
    color: rgba(0, 0, 0, 0.54);
    fill: rgba(0, 0, 0, 0.54);
    background-color: transparent;
}

.paginationbuttons{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
}


.accordion-section .container {
    padding: 0;
}

.accordion {
    padding: 0 18%;
}

.accordion-tab {
    border-bottom: 1px solid black;
}

.accordion-tab:last-child {
    border-bottom: 0;
}

.faq-heading {
    padding: 50px 0px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    position: relative;
    cursor: pointer;
    color: black;
    transition: 0.4s ease;
}

.faq-heading::before,
.faq-heading::after {
    content: '';
    position: absolute;
    top: 50%;
    background-color: black;
    transition: all .3s;
}

.faq-heading::before {
    right: 30px;
    width: 3px;
    height: 20px;
    margin-top: -10px;
}

.faq-heading::after {
    right: 21px;
    width: 20px;
    height: 3px;
    margin-top: -2px;
}


.contents {
    display: none;
    padding: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #000000;
}

.sec-faq-heading {
    font-weight: 600;
    font-size: 70px;
    line-height: 88px;
    text-align: center;
    color: #000000;
}

.accordion-section {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 130px 0;
}

.faq-heading {
    padding: 50px 0px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    position: relative;
    cursor: pointer;
    color: #000000;
    transition: 0.4s ease;
}

.faq-heading::before,
.faq-heading::after {
    content: '';
    position: absolute;
    top: 50%;
    background-color: #000000;
    transition: all .3s;
}

.faq-heading::before {
    right: 30px;
    width: 3px;
    height: 20px;
    margin-top: -10px;
}

.faq-heading::after {
    right: 21px;
    width: 20px;
    height: 3px;
    margin-top: -2px;
}

.accordion-tab.active .faq-heading {
    color: #FB1417;
}

.accordion-tab.active .faq-heading::after {
    background-color: #FB1417;
}

.accordion-tab.active .faq-heading::before {
    background-color: #FB1417;
}

.accordion-tab.active .faq-heading::before {
    transform: rotate(90deg);

}

.accordion-section .contents {
    display: none;
    padding: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #000000;
}

.accordion-section {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding:0px;
}

.accordion-section h2 {
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #000000;
}

.form-control.form-1.phone {
    padding: 0 25px 0 10px;
}
.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    border: none;
    height: 48px;
}
.PhoneInput {
    display: flex;
    align-items: center;
    max-height: 50px;
}
.form-control[type="date"]::-webkit-calendar-picker-indicator {
    width: 16px;
    height: 16px;
    filter: invert(0.5);
}
input.form-control.form-1.dob {
    padding: 0 25px 0 8px;
}
.form-control[type="date"] {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.library-img-container {
    background: #fff;
    border: 2px solid transparent!important;
    border-radius: 15px!important;
    box-shadow: 0 6px 39px rgba(196,203,214,.2);
    margin-bottom: 10px;
    padding: 14px;
    height: 95%;
    padding: 16px;
    text-align: center;
}
.library-img-container .title_box h6{
    padding-right: unset;
}

.library-nav {
    padding: unset !important;
}


.library-img-box{
    align-items: center;
    background: #fff;
    border: 1px solid #f1f2f6;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    height: 174px;
}
.library-img-box img{
    width: 500px;
    height: 100%;
    object-fit: cover;
    /* object-fit: contain; */
    object-position: center;
}

.library-detail-img-box{
    height: 100%;
    max-height: 463px;
}

.spin-loader {
    text-align: center;
    height: 50px;
    margin-top: 20px;
    padding-top: 20px;
}

.css-kud8pv {
    position: relative;
    font-size: 0;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
}

.wrapper {
    padding: 2rem 0;
}

.detail-info {
    text-align: center;
    padding: 0 10px;
}

.detail-box {
    height: auto;
}
.detail-box-img{
    max-height: 463px;
    height: 100%;
    overflow: hidden;
}

.detail-box-img img {
    object-fit: scale-down;
}

.library-empty-page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    border: 2px solid gray;
    border-radius: 1rem;
    margin-bottom: 1rem;
    background-color: #f6f6f6;
}

.library-empty-page span {
    font-size: 26px;
    font-weight: 400;
    color: gray;
}

.static-img{
    height: auto;
    margin-bottom: 0;
}
.static-img .library-img-box{
    margin-bottom: 0;
}
span.description {
    display: inherit;
    font-weight: 600;
}

@media only screen and (max-width: 1200px) {
    .library-img-box img {
        width: 500px;
        height: 100%;
        object-fit: contain;
    }

  }

  .custom-img-object img {
    width: 145px;
  }